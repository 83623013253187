<script>
  import DataTable, { Body, Cell, Label, Row } from "@smui/data-table";

  /**
   * グループ化された各項目のタイトル。
   * @type {string}
   */
  export let groupingTitle;
  /**
   * グループ化された詳細情報の配列。
   * @type {Array<{ name: string, info: string | number | boolean, html?: boolean }>}
   */
  export let groupingDetails;
</script>

<p class="subtitle">{groupingTitle}</p>

<div class="dataTableWrapper" style="width: 100%; min-width: 635px;">
  <DataTable table$aria-label={groupingTitle} style="width: 100%;">
    <Body>
      {#each groupingDetails as { name, info, html }}
        <Row style="height: 30px;">
          <Cell style="width: 220px; min-width: 220px; padding-top: 5px;">
            <Label>{name}</Label>
          </Cell>
          <Cell style="width: calc(100vw - 220px);">
            {#if info !== undefined && info !== null}
              {#if html}{@html info}<!-- pre-escaped -->
              {:else}<Label>{info}</Label>{/if}
            {:else}
              <Label>未登録</Label>
            {/if}
          </Cell>
        </Row>
      {/each}
    </Body>
  </DataTable>
</div>

<style lang="scss">
  .dataTableWrapper {
    margin: 6px 0;

    :global(.mdc-data-table__table) {
      white-space: normal;
    }

    :global(.communicationArea) {
      width: 370px;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-top: 8px;
      padding: 4px;
      font-size: 12px;
      word-wrap: break-word;
    }
    :global(.communicationArea + .note) {
      margin: 4px 0 8px 0;
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.2em;
      font-size: 11px;
      color: #666;
    }
  }

  .subtitle {
    display: inline-block;
    padding: 2px 10px 0;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    background: #d9d9d9;
    border-left: solid 12px #1976d2;
  }
</style>

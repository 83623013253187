<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import CloseIcon from "~/components/icons/close.svelte";
  import backendApi from "~/libs/backendApi";
  import { RETURN_STATUS_REQUESTING, ReturnReason } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let maskedDetailedShipment;

  /** @type {Function} */
  export let confirmCancelDeliveryDialog;

  let open;
  let returnReasonList = [
    ReturnReason.REQUESTED_RETURN_FROM_EC,
    ReturnReason.REQUESTED_CANCEL_FROM_RECEIVER,
  ];
  let agreeCancelChecked = false;
  /** @type { 0 | 1 } */
  let selectedReturnReason;

  /**
   * キャンセル画面を開く。
   */
  export function dialogOpen() {
    open = true;
  }

  /**
   * キャンセル画面を閉じる。
   */
  function closeHandler() {
    agreeCancelChecked = false;
    selectedReturnReason = null;
    open = false;
  }

  /**
   * 確定ボタンを押したときの処理
   */
  async function confirmHandler() {
    try {
      await execStatusUpdateApi();
      confirmCancelDeliveryDialog(selectedReturnReason);
      toast.info($_("message.updateComplete"));
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.updateUnauthorized.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
    open = false;
  }

  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} */
    const updateShipmentEvent = {
      trackingNumber: maskedDetailedShipment.trackingNumber,
      status: maskedDetailedShipment.status,
      version: maskedDetailedShipment.version,
    };

    updateShipmentEvent.returnStatus = RETURN_STATUS_REQUESTING;
    updateShipmentEvent.returnReason = selectedReturnReason;

    return await backendApi.updateShipment(updateShipmentEvent);
  });
</script>

<div class="dialogWrapper">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 101;"
  >
    <Title id="cancel-dialog-title">
      <p>キャンセル確認</p>
      <div class="closeButtonArea">
        <button class="closeButton" on:click={closeHandler}>
          <CloseIcon /></button
        >
      </div>
    </Title>
    <Content id="detail-dialog-content">
      <div class="noteArea">
        <p>
          配達の中断と返品の手配を行うことができます。<br />
          配達のキャンセルを行ったお荷物を再度お客様に配達することは出来かねますので、予めご了承ください。
        </p>
        {#if maskedDetailedShipment?.status === 3}
          <div class="alertMessage">
            <span class="material-icons .md-18"> warning_amber </span>
            <p>
              該当のお荷物は宅配ドライバーによって持ち出されているため、入れ違いで配達してしまう可能性がございます。<br
              />
              その場合、お荷物の回収は出来かねますので、お客様に直接ご連絡ください。
            </p>
          </div>
        {/if}
        {#if maskedDetailedShipment?.numberOfPackages > 1}
          <!-- 複数個口の場合 -->
          <div class="alertMessage">
            <span class="material-icons .md-18"> warning_amber </span>
            <p>
              複数口（全{maskedDetailedShipment.numberOfPackages}個）まとめてのキャンセルとなります。
            </p>
          </div>
        {/if}
        <FormField>
          <Checkbox bind:checked={agreeCancelChecked} />
          <span slot="label">同意して配達のキャンセルを行う</span>
        </FormField>
      </div>
      {#if agreeCancelChecked}
        <div class="cancelReasonArea">
          <span>キャンセルの理由を選択してください</span>
          {#each returnReasonList as returnReason}
            <FormField>
              <Radio bind:group={selectedReturnReason} value={returnReason} />
              <span slot="label">
                {$_(`classes.returnReasonByEc.${returnReason}`)}
                {#if returnReason === ReturnReason.REQUESTED_RETURN_FROM_EC}
                  <span class="example">
                    {$_(`classes.returnReasonExampleByEc.${returnReason}`)}
                  </span>
                {/if}
              </span>
            </FormField>
          {/each}
        </div>
        <p class="attentionText">
          ※キャンセル後の取り消しは出来かねますのでご注意ください。
        </p>
      {/if}
    </Content>
    <div class="buttonArea">
      <Button
        touch
        variant="unelevated"
        disabled={!agreeCancelChecked || selectedReturnReason === undefined}
        on:click={confirmHandler}>配達をキャンセルする</Button
      >
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialogWrapper {
    :global(#cancel-dialog-title) {
      display: flex;
      padding-top: 20px;
    }
  }
  .closeButtonArea {
    margin: 0 0 0 auto;
    button {
      padding: 4px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 50%;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .noteArea {
    color: rgba(0, 0, 0, 0.7);
    .alertMessage {
      margin: 10px 0;
      padding: 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 14px;
    }
    .material-icons {
      color: #d74141;
    }
  }
  .cancelReasonArea {
    position: relative;
    margin: 16px 0;
    padding: 8px 16px;
    border: solid 2px #018786;
    border-radius: 4px;
    > span {
      position: absolute;
      display: inline-block;
      top: -13px;
      left: 10px;
      padding: 0 9px;
      line-height: 1;
      font-size: 16px;
      background: #fff;
      color: #018786;
      font-weight: bold;
    }
    .example {
      display: inline-block;
      margin-top: 2px;
      margin-left: 5px;
      font-size: 12px;
      color: var(--mdc-theme-secondary);
    }
  }
  .attentionText {
    color: red;
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    margin: 0 16px 8px 0;
  }
</style>

<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import CloseIcon from "~/components/icons/close.svelte";
  import backendApi from "~/libs/backendApi";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  /** @type {Function} */
  export let confirmLostRegistrationDialog;

  /** @type {boolean} 変更前の紛失フラグの状態 */
  $: currentLostFlg = shipment?.lost;
  let open;

  /** 複数個口の荷物であることの了承チェック @type {boolean} */
  let agreeMultipleChecked = false;

  /**
   * 紛失登録・解除画面を開く。
   */
  export function dialogOpen() {
    open = true;
  }

  /**
   * 紛失登録・解除画面を閉じる。
   */
  function closeHandler() {
    open = false;
  }

  /**
   * 確定ボタンを押したときの処理
   */
  async function confirmHandler() {
    try {
      await execStatusUpdateApi();
      confirmLostRegistrationDialog(!currentLostFlg);
      toast.info($_("message.updateComplete"));
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.updateUnauthorized.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
    open = false;
  }

  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };

    updateShipmentEvent.lost = !currentLostFlg;

    return await backendApi.updateShipment(updateShipmentEvent);
  });
</script>

<div class="dialogWrapper">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 101;"
  >
    <Title id="cancel-dialog-title">
      <p>紛失{currentLostFlg ? "解除" : "登録"}確認</p>
      <div class="closeButtonArea">
        <button class="closeButton" on:click={closeHandler}>
          <CloseIcon /></button
        >
      </div>
    </Title>
    <Content id="detail-dialog-content">
      <div class="noteArea">
        <p>
          {#if currentLostFlg}
            この荷物の<strong>配達不能（紛失）</strong>状態を解除します。<br />
            荷物の紛失への対応状況（代替品の手配や返金手続き等）を荷主様へご確認いただいた上で、
            見つかった荷物を配達する場合にのみ解除を行ってください。<br />
          {:else}
            この荷物を<strong>配達不能（紛失）</strong>として登録します。<br />
            荷物を捜索中の場合には登録を行わず、捜索の結果見つからなかった場合にのみ登録してください。<br
            />
            ご登録の際は、荷物を紛失した旨を荷主様へ確実にご連絡ください。
          {/if}
        </p>
        {#if shipment?.numberOfPackages > 1}
          <!-- 複数個口の場合 -->
          <div class="alertMessage">
            <span class="material-icons .md-18"> warning_amber </span>
            <p>
              複数口（全{shipment.numberOfPackages}個）の荷物です。<br />
              一部を紛失した場合は通信欄に詳細を記載してください。
            </p>
          </div>
          <FormField>
            <Checkbox bind:checked={agreeMultipleChecked} />
            <span slot="label">同意して紛失の登録を行う</span>
          </FormField>
        {/if}
      </div>
    </Content>
    <div class="buttonArea">
      <Button
        touch
        variant="unelevated"
        disabled={shipment?.numberOfPackages > 1 && !agreeMultipleChecked}
        on:click={confirmHandler}
        >{currentLostFlg
          ? "配達不能（紛失）状態を解除する"
          : "配達不能（紛失）として登録する"}</Button
      >
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialogWrapper {
    :global(#cancel-dialog-title) {
      display: flex;
      padding-top: 20px;
    }
  }
  .closeButtonArea {
    margin: 0 0 0 auto;
    button {
      padding: 4px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 50%;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .noteArea {
    .alertMessage {
      margin: 10px 0;
      padding: 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 14px;
    }
    .material-icons {
      color: #d74141;
    }
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    margin: 0 16px 8px 0;
  }
</style>

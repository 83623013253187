<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import CloseIcon from "~/components/icons/close.svelte";
  import backendApi from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    RETURN_STATUS_WAITING,
    RecoveryResult,
    ReturnReason,
    STATUS_HELD_IN_DEPOT,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let maskedDetailedShipment;

  /** @type {Function} */
  export let confirmMisdeliveryRegisterDialog;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let open;
  let recoveryResultList = [
    RecoveryResult.FAILURE,
    RecoveryResult.SUCCESS_WITHOUT_DAMAGE,
    RecoveryResult.SUCCESS_WITH_DAMAGE,
  ];
  /** @type { 0 | 1 | 2 } */
  let selectedRecoveryResult;

  /**
   * @typedef {import("~/libs/commonTypes").DepotLocation & {centers: Array<{distance: string, isNeary: boolean}>}} ExtraDepotLocation
   */
  /** @type {Array<ExtraDepotLocation>} */
  let locationList = [];
  let selectCenter;

  /** 複数個口の荷物であることの了承チェック @type {boolean} */
  let agreeMultipleChecked = false;

  // ページの初期化処理（非同期）
  (async () => {
    // 作業場所（配送センター）の取得
    locationList = /** @type {Array<ExtraDepotLocation>} */ (
      await depotLocations.get()
    );
  })();

  /**
   * キャンセル画面を開く。
   */
  export function dialogOpen() {
    open = true;
  }

  /**
   * キャンセル画面を閉じる。
   */
  function closeHandler() {
    selectedRecoveryResult = null;
    open = false;
  }

  /**
   * 登録ボタンを押したときの処理
   */
  async function confirmHandler() {
    try {
      await execStatusUpdateApi();
      confirmMisdeliveryRegisterDialog(selectedRecoveryResult);
      toast.info($_("message.updateComplete"));
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.updateUnauthorized.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
    open = false;
  }

  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    let misdeliveryMessage = "";
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} */
    const updateShipmentEvent = {
      trackingNumber: maskedDetailedShipment.trackingNumber,
      status: maskedDetailedShipment.status,
      version: maskedDetailedShipment.version,
    };

    if (selectedRecoveryResult === 0) {
      // IF: 荷物を回収できなかった場合
      updateShipmentEvent.misdelivered = true;
      updateShipmentEvent.misdeliveryState = 0;
      misdeliveryMessage =
        "------システムより登録\n【登録日時】" +
        getFormattedCurrentDateTime() +
        "\n【登録者】" +
        userContext.getUserName() +
        "\n【事象】誤送登録が行われました。\n※荷物を回収できなかった・紛失扱い\n------\n";
    } else if (selectedRecoveryResult === 1) {
      // IF: 荷物を回収できた（未開封かつ損壊なし）の場合
      updateShipmentEvent.status = STATUS_HELD_IN_DEPOT;
      misdeliveryMessage =
        "------システムより登録\n【登録日時】" +
        getFormattedCurrentDateTime() +
        "\n【登録者】" +
        userContext.getUserName() +
        "\n【事象】誤送登録が行われました。\n※荷物を回収できた（未開封かつ損壊なし）・再配達対象\n------\n";
    } else if (selectedRecoveryResult === 2) {
      // IF: 荷物を回収できた（開封済または損壊あり）の場合
      updateShipmentEvent.misdelivered = true;
      updateShipmentEvent.misdeliveryState = 1;
      updateShipmentEvent.returnStatus = RETURN_STATUS_WAITING;
      updateShipmentEvent.returnReason = ReturnReason.SHIPMENT_PROBLEM;
      updateShipmentEvent.locationId = selectCenter;
      misdeliveryMessage =
        "------システムより登録\n【登録日時】" +
        getFormattedCurrentDateTime() +
        "\n【登録者】" +
        userContext.getUserName() +
        "\n【事象】誤送登録が行われました。\n※荷物を回収できた（開封済または損壊あり）・返品対象\n------\n";
    }
    maskedDetailedShipment.delivererInternalMessage?.length > 0
      ? (updateShipmentEvent.delivererInternalMessage =
          maskedDetailedShipment.delivererInternalMessage +
          "\n" +
          misdeliveryMessage)
      : (updateShipmentEvent.delivererInternalMessage = misdeliveryMessage);

    return await backendApi.updateShipment(updateShipmentEvent);
  });

  /**
   * 現在の日時を取得し、YYYY-MM-DD hh:mm:ss形式にフォーマットする。
   * @returns {string} フォーマット済みの日時
   */
  function getFormattedCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  }
</script>

<div class="dialogWrapper">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 101;"
  >
    <Title id="cancel-dialog-title">
      <p>誤送登録確認</p>
      <div class="closeButtonArea">
        <button class="closeButton" on:click={closeHandler}>
          <CloseIcon /></button
        >
      </div>
    </Title>
    <Content id="detail-dialog-content">
      <div class="noteArea">
        <p>
          誤送した荷物の回収結果を登録します。<br />
          誤送が判明した後、誤送先からの回収結果（以下3パターン）が出るまでは登録しないでください。
        </p>
        <div class="resultSelectArea">
          <p>【回収結果】</p>
          {#each recoveryResultList as recoveryResult}
            <FormField>
              <Radio
                bind:group={selectedRecoveryResult}
                value={recoveryResult}
              />
              <span slot="label">
                {$_(`classes.recoveryResults.${recoveryResult}`)}
              </span>
            </FormField>
            <div class="futureActionsArea">
              {#if recoveryResult === RecoveryResult.FAILURE && selectedRecoveryResult === RecoveryResult.FAILURE}
                <p>
                  誤送した荷物を回収できなかった旨を荷主様に連絡し、今後の対応について調整してください。
                </p>
              {:else if recoveryResult === RecoveryResult.SUCCESS_WITHOUT_DAMAGE && selectedRecoveryResult === RecoveryResult.SUCCESS_WITHOUT_DAMAGE}
                <p>
                  配達完了から持出前の状態に戻るため、通常通り荷物を持ち出して配達してください。
                </p>
                <div class="initializationWarning">
                  <span class="material-icons"> report_problem </span>
                  <ul>
                    <li>
                      配送ステータスが「配達完了」から「保管中」に戻ります。
                    </li>
                    <li>
                      「宅配ドライバー名」「配達完了（日時／場所）」「受け渡し方法（実績）」「宅配ボックス番号」「宅配ボックス暗証番号」「置き配写真」がクリアされます。
                    </li>
                    <li>
                      「通信欄(配送事業者内)」に誤送登録した旨が記録されます。
                    </li>
                  </ul>
                </div>
              {:else if recoveryResult === RecoveryResult.SUCCESS_WITH_DAMAGE && selectedRecoveryResult === RecoveryResult.SUCCESS_WITH_DAMAGE}
                <p>
                  荷物が損壊したときと同様に返品する必要があるため、返品用のカゴ車に回収した荷物を入れてください。
                </p>
                <p>荷主様に連絡し、今後の対応について調整してください。</p>
                <div class="inputCenterArea">
                  {#if locationList != null}
                    <label class="inputCenterLabel">
                      <select
                        name="inputCenter"
                        class="selectInput"
                        id="inputCenter"
                        bind:value={selectCenter}
                      >
                        <option value="" selected disabled
                          >回収した荷物を保管した配送センターを選択してください</option
                        >
                        {#each locationList as { prefecture, centers }}
                          <optgroup label={prefecture}>
                            {#each centers as { id, name }}
                              <option value={id}>{name}</option>
                            {/each}
                          </optgroup>
                        {/each}
                      </select>
                    </label>
                  {:else}
                    <select
                      name="inputCenter"
                      class="selectInput"
                      id="inputCenter"
                      disabled
                    >
                      <option selected>取得失敗</option>
                    </select>
                  {/if}
                </div>
                {#if !selectCenter}
                  <p class="notes">未入力の項目があります。</p>
                {/if}
              {/if}
            </div>
          {/each}
        </div>
        {#if maskedDetailedShipment?.numberOfPackages > 1}
          <!-- 複数個口の場合 -->
          <div class="alertMessage">
            <span class="material-icons .md-18"> warning_amber </span>
            <p>
              複数口（全{maskedDetailedShipment.numberOfPackages}個）の荷物です。<br
              />
              一部を誤送した場合は通信欄に詳細を記載してください。
            </p>
          </div>
          <FormField>
            <Checkbox bind:checked={agreeMultipleChecked} />
            <span slot="label">同意して誤送の登録を行う</span>
          </FormField>
        {/if}
      </div></Content
    >
    <div class="buttonArea">
      <Button
        touch
        variant="unelevated"
        disabled={selectedRecoveryResult === undefined ||
          (selectedRecoveryResult === 2 && !selectCenter) ||
          (maskedDetailedShipment?.numberOfPackages > 1 &&
            !agreeMultipleChecked)}
        on:click={confirmHandler}>登録する</Button
      >
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialogWrapper {
    :global(#cancel-dialog-title) {
      display: flex;
      padding-top: 20px;
    }
  }
  .closeButtonArea {
    margin: 0 0 0 auto;
    button {
      padding: 4px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 50%;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .noteArea {
    .alertMessage {
      margin: 10px 0;
      padding: 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 14px;
    }
    .material-icons {
      color: #d74141;
    }
  }
  .resultSelectArea {
    margin-top: 24px;
    color: #333;
    > p {
      margin-bottom: 8px;
    }
  }
  .futureActionsArea {
    font-size: 13px;
    margin-left: 45px;
  }
  .initializationWarning {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 10px 12px 14px;
    margin: 8px 0;
    background-color: #ffe7e7;
    border-radius: 4px;
    line-height: 20px;
    .material-icons {
      color: #c80000;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: disc;
      margin-left: 24px;
      margin-top: 6px;
    }
  }
  .inputCenterArea {
    .inputCenterLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;
      margin-top: 16px;
      &::after {
        position: absolute;
        right: 15px;
        width: 10px;
        height: 7px;
        background-color: #535353;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        padding: 0.4em 30px 0.4em 0.8em;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }
    }
    .selectInput {
      height: 40px;
    }
  }
  .notes {
    width: 100%;
    text-align: left;
    line-height: 15px;
    margin-top: 3px;
    font-size: 12px;
    color: red;
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    margin: 0 16px 8px 0;
  }
</style>

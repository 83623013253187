<svg
  id="_レイヤー_1"
  data-name="レイヤー 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 16.41 20.41"
>
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }

      .cls-1,
      .cls-2 {
        stroke-width: 0px;
      }

      .cls-2 {
        fill: #0051a5;
      }
    </style>
  </defs>
  <path
    class="cls-2"
    d="m2.28,20.41c-.63,0-1.17-.22-1.61-.67-.44-.44-.67-.98-.67-1.61V2.28c0-.63.22-1.17.67-1.61C1.11.22,1.65,0,2.28,0h7.07c.3,0,.59.06.87.17.28.11.52.28.73.49l4.8,4.8c.21.21.37.45.49.73.11.28.17.56.17.87v11.07c0,.63-.22,1.17-.67,1.61-.44.44-.98.67-1.61.67H2.28Zm6.86-14.27c0,.32.11.59.33.81.22.22.49.33.81.33h3.86l-5-5v3.86Z"
  />
  <path
    class="cls-1"
    d="m5.07,14.95h1.15c.11,0,.2-.04.27-.11.07-.07.11-.16.11-.27,0-.11-.04-.2-.11-.27-.07-.07-.16-.11-.27-.11h-.89v-1.53h.89c.11,0,.2-.04.27-.11.07-.07.11-.16.11-.27,0-.11-.04-.2-.11-.27-.07-.07-.16-.11-.27-.11h-1.15c-.14,0-.27.05-.36.15s-.15.22-.15.36v2.04c0,.14.05.27.15.36s.22.15.36.15Zm2.34,0h1.15c.14,0,.27-.05.36-.15s.15-.22.15-.36v-.76c0-.14-.05-.28-.15-.4s-.22-.18-.36-.18h-.76v-.44h.89c.11,0,.2-.04.27-.11s.11-.16.11-.27-.04-.2-.11-.27-.16-.11-.27-.11h-1.15c-.14,0-.27.05-.36.15s-.15.22-.15.36v.76c0,.14.05.27.15.39s.22.17.36.17h.76v.46h-.89c-.11,0-.2.04-.27.11s-.11.16-.11.27.04.2.11.27.16.11.27.11Zm3.32-1.3l-.43-1.49c-.03-.08-.07-.14-.13-.19s-.14-.08-.22-.08c-.12,0-.21.05-.29.15-.07.1-.09.21-.06.32l.68,2.32c.03.08.07.14.13.19s.14.08.22.08h.2c.08,0,.16-.03.22-.08s.11-.11.13-.19l.68-2.32c.03-.12.01-.23-.06-.32-.07-.1-.17-.15-.29-.15-.08,0-.16.03-.22.08s-.11.11-.13.19l-.43,1.49Zm-6.57,3.95c-.32,0-.59-.11-.82-.34-.23-.23-.34-.5-.34-.82v-6.04c0-.32.11-.59.34-.82.23-.23.5-.34.82-.34h8.08c.32,0,.59.11.82.34.23.23.34.5.34.82v6.04c0,.32-.11.59-.34.82s-.5.34-.82.34H4.16Z"
  />
</svg>

/**
 * @type {{
 *   appContext: string,
 *   userContext: string,
 * }}
 */
export default Object.freeze({
  get appContext() {
    return localStorage.getItem("appContext");
  },
  set appContext(value) {
    if (value == null || value == undefined) {
      localStorage.removeItem("appContext");
    } else {
      localStorage.setItem("appContext", value);
    }
  },

  get userContext() {
    return localStorage.getItem("userContext");
  },
  set userContext(value) {
    if (value == null || value == undefined) {
      localStorage.removeItem("userContext");
    } else {
      localStorage.setItem("userContext", value);
    }
  },
});

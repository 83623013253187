import backendApi from "~/libs/backendApi";

/** @type {Array<import("~/libs/commonTypes").DepotLocation>} キャッシュ済の配送センター一覧 */
let depotLocationsCache = [];

/** @type {number} キャッシュの最終更新日時 */
let lastUpdated = 0;

// TODO: 配送センター情報をmutableでそのまま返しているので、ディープコピーして返すことを検討（今のところ呼出元で変更している箇所がないので支障はない）
const depotLocations = {
  /**
   * 配送センターの一覧を返します。
   * @param {boolean} forceLatest 強制的に最新の一覧を取得する場合はtrue
   * @returns {Promise<Array<import("~/libs/commonTypes").DepotLocation>>} 配送センターの一覧
   */
  async get(forceLatest = false) {
    const currentTime = Date.now();
    if (
      depotLocationsCache.length === 0 ||
      forceLatest ||
      currentTime - lastUpdated >= 1000 * 60 * 60 * 12
    ) {
      // キャッシュがない場合、強制取得フラグが立っている場合、もしくは前回取得から12時間以上経過している場合はawaitで取得
      depotLocationsCache = await backendApi.getDepotLocations();
      lastUpdated = currentTime;
    } else {
      // キャッシュがある場合はキャッシュを返すとともに非同期でキャッシュを更新（前回取得から30分以上経過している場合）
      if (currentTime - lastUpdated >= 1000 * 60 * 30) {
        backendApi
          .getDepotLocations()
          .then((depotLocations) => {
            depotLocationsCache = depotLocations;
            lastUpdated = currentTime;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    return depotLocationsCache;
  },

  /**
   *
   * @param {boolean} forceLatest 強制的に最新の一覧を取得する場合はtrue
   * @returns {Promise<Map<number, object>>} 配送センターIDをキーとしたセンター情報のMap
   */
  async getCentersMap(forceLatest = false) {
    await this.get(forceLatest);
    const centersMap = new Map();
    depotLocationsCache.forEach((location) => {
      location.centers.forEach((center) => {
        centersMap.set(center.id, center);
      });
    });
    return centersMap;
  },
};

export default Object.freeze(depotLocations);

<script>
  import Button, { Label } from "@smui/button";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import logo from "~/assets/images/logo.svg";
  import backendApi, { ErrorResponseException } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    EC_ROLES,
    PERMIT_LOGIN_ROLES,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { activeContent } from "~/libs/stores";

  /** ユーザーID @type {string} */
  export let id;
  /** パスワード @type {string} */
  export let pw;
  /** 初期パスワード変更ページを表示するか否か @type {boolean} */
  export let showsInitialPasswordChangePage;
  /** パスワードリセットページを表示するか否か @type {boolean} */
  export let showsPasswordResetPage;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** パスワードのtype属性値 */
  let pwType = "password";

  /** エラーメッセージ @type {string} */
  let errorMessage;

  /** 「ログイン」ボタンのdisabled属性を有効にするか否か */
  $: loginButtonDisabled = !(id && pw);

  (() => {
    if (!userContext.needsLogin()) {
      console.log("認証トークンが有効なためホーム画面に遷移");
      activeContent.set("Home");
      push("/home");
    } else if (userContext.loginUser) {
      userContext.erase();
      showErrorMessage(
        new HandledError(
          $_("errors.unauthorized.title"),
          $_("errors.unauthorized.message"),
        ),
      );
    }
  })();

  const login = loadingProgress.wrapAsync(async () => {
    try {
      // ログインAPI呼び出し
      const loginResponse = await backendApi.login({
        username: id,
        password: pw,
      });

      // ログイン可能なユーザーロールかチェック
      if (
        !loginResponse.roles.some((role) => PERMIT_LOGIN_ROLES.includes(role))
      ) {
        throw new HandledError(
          $_("errors.forbidden.title"),
          $_("errors.forbidden.message"),
        );
      }

      // ログイン情報をUserContextに保存（ユーザー取得API呼び出し用の暫定）
      userContext.loginUser = {
        username: loginResponse.username,
        roles: loginResponse.roles,
        accessToken: loginResponse.accessToken,
        expiresIn: loginResponse.expiresIn,
        loginTime: Date.now(),
        displayName: loginResponse.displayName,
        companyId: loginResponse.companyId,
        companyName: loginResponse.companyName,
        currentCompanyId: loginResponse.currentCompanyId,
        emailAddress: loginResponse.emailAddress,
        switchableRoles: loginResponse.switchableRoles,
        switchableCompanies: loginResponse.switchableCompanies,
      };

      // EC設定取得API呼び出し
      if (loginResponse.roles.some((role) => EC_ROLES.includes(role))) {
        try {
          const ecSettings = await backendApi.getEcSettings();
          // EC設定情報をUserContextに追加設定
          userContext.ecSettings = ecSettings;
        } catch (error) {
          userContext.erase(); // 暫定設定を取り消し
          throw error;
        }
      }

      // UserContextの設定を保存してホーム画面に移動
      userContext.store();
      activeContent.set("Home");
      push("/home");
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "Require password reset."
      ) {
        // 初期パスワード変更が必要な場合
        showsInitialPasswordChangePage = true;
      } else {
        showErrorMessage(error);
      }
    }
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      errorMessage = error.message;
    } else {
      if (error instanceof HTTPError && error.response?.status == 401) {
        errorMessage = $_("errors.loginFailed.message");
      } else if (error instanceof HTTPError && error.response?.status == 400) {
        errorMessage = $_("errors.loginFailedLimitOrver.message");
      } else {
        console.error(error);
        errorMessage = $_("errors.loginDefaultMessage.message");
      }
    }
  }

  function onEnterKeyDownHandler(/** @type {KeyboardEvent} */ event) {
    if (event.key === "Enter" && !loginButtonDisabled) {
      login();
    }
  }
</script>

<div class="heading">
  <p class="logo">
    <img src={logo} width="100" alt="logo" />
  </p>
</div>

<div class="inputField">
  <Textfield
    type="text"
    label="ユーザーID"
    variant="outlined"
    style="margin-top: 30px;"
    required
    input$id="username"
    input$name="username"
    input$autocomplete="username"
    input$autofocus={true}
    bind:value={id}
    on:keydown={onEnterKeyDownHandler}
  />
</div>
<div class="inputField passwordField">
  <Textfield
    type={pwType}
    label="パスワード"
    variant="outlined"
    style="margin-top: 15px;"
    required
    input$id="current-password"
    input$name="current-password"
    input$autocomplete="current-password"
    bind:value={pw}
    on:keydown={onEnterKeyDownHandler}
  >
    <IconButton
      slot="trailingIcon"
      class="material-icons md-dark"
      tabindex={-1}
      on:click={() => {
        pwType = pwType == "text" ? "password" : "text";
      }}
    >
      {pwType == "text" ? "visibility_off" : "visibility"}
    </IconButton>
  </Textfield>
</div>

<div class="passwordReset">
  <Button
    color="secondary"
    ripple={false}
    on:click={() => {
      showsPasswordResetPage = true;
    }}
  >
    <Label>パスワードをお忘れの方はこちら</Label>
  </Button>
</div>

{#if errorMessage}
  <p class="errorMessage">
    <!-- ja.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
    {@html errorMessage}
  </p>
{/if}

<div class="command">
  <Button
    type="submit"
    variant="unelevated"
    style="width: 150px; height: 50px;"
    touch
    bind:disabled={loginButtonDisabled}
    on:click={login}
    >ログイン
  </Button>
</div>

<style lang="scss">
  .passwordReset {
    :global(button) {
      font-size: 14px;
      font-weight: normal;
      text-decoration: underline;
    }
  }
</style>

<script>
  import Button from "@smui/button";
  import DataTable, { Body, Cell, Head, Label, Row } from "@smui/data-table";
  import { getContext, onDestroy } from "svelte";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { managementResultEditClose, messageDialogClose } from "~/libs/stores";
  import ManagementResultEdit from "~/pages/Management/ManagementResultEdit.svelte";

  /** @type {Array<import("~/libs/commonTypes").CustomedUserInfo>} */
  export let results;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("svelte/store").Unsubscriber} */
  let managementResultEditCloseUnsubscriber;

  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;

  let managementResultEdit;
  let target;
  let displayingDetails = false;
  let changeButtonDisabled = userContext.hasPermitUserManagementRole()
    ? false
    : true;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  let result;

  managementResultEditCloseUnsubscriber = managementResultEditClose.subscribe(
    (isClosed) => {
      if (displayingDetails && isClosed) {
        displayingDetails = false;
        managementResultEdit = null;
        managementResultEditClose.set(false);
        if (result == "success" || result == "failed") {
          result = null;
          dialogComponent = MessageDialog;
        }
      }
    },
  );

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogTitle = null;
    dialogMessage = null;
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  async function openWindow(result) {
    target = result;
    displayingDetails = true;
    managementResultEdit = null;
    managementResultEdit = await ManagementResultEdit;
  }

  function handleMessage(event) {
    result = event.detail.result;
    dialogTitle = event.detail.title;
    dialogMessage = event.detail.message;
  }

  onDestroy(() => {
    managementResultEditCloseUnsubscriber?.();
    messageDialogCloseUnsubscriber?.();
  });
</script>

<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />
<svelte:component
  this={managementResultEdit}
  result={target}
  on:message={handleMessage}
  {companyNameList}
/>
<DataTable stickyHeader style="width:98%; margin: 0 1% 1%;">
  <Head>
    <Row>
      <Cell
        columnId="companyId"
        style="width:160px; background-color:rgb(236, 245, 255); padding-top: 16px;"
      >
        <Label>会社名</Label>
      </Cell>
      <Cell columnId="userName" style="background-color:rgb(236, 245, 255);">
        <Label>ユーザーID</Label>
      </Cell>
      <Cell columnId="displayName" style="background-color:rgb(236, 245, 255);">
        <Label>表示名</Label>
      </Cell>
      <Cell columnId="displayName" style="background-color:rgb(236, 245, 255);">
        <Label>メールアドレス</Label>
      </Cell>
      <Cell columnId="disabled" style="background-color:rgb(236, 245, 255);">
        <Label>アカウント状態</Label>
      </Cell>
      <Cell sortable={false} style="background-color:rgb(236, 245, 255);" />
    </Row>
  </Head>
  <Body>
    {#each results as result}
      <Row>
        <Cell style="padding-top: 16px;">{result.companyName}</Cell>
        <Cell>{result.userName}</Cell>
        <Cell>{result.displayName}</Cell>
        <Cell>{result.emailAddress ?? ""}</Cell>
        {#if result.disabled}
          <Cell style="color: #F90404;">無効</Cell>
        {:else}
          <Cell />
        {/if}
        <Cell>
          <Button
            fill
            on:click={() => {
              openWindow(result);
            }}
            bind:disabled={changeButtonDisabled}
            style="margin-left: -16px;">変更</Button
          >
        </Cell>
      </Row>
    {/each}
  </Body>
</DataTable>

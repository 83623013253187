<script>
  import InitialPasswordChange from "~/pages/Login/InitialPasswordChange.svelte";
  import NormalLogin from "~/pages/Login/NormalLogin.svelte";
  import PasswordReset from "~/pages/Login/PasswordReset.svelte";

  /** ユーザーID */
  let id = "";
  /** パスワード*/
  let pw = "";

  /** 初期パスワード変更ページを表示するか否か */
  let showsInitialPasswordChangePage = false;

  /** パスワードリセットページを表示するか否か */
  let showsPasswordResetPage = false;
</script>

<main>
  <div class="wrapper">
    {#if showsInitialPasswordChangePage}
      <!-- 初期パスワード変更 -->
      <InitialPasswordChange {id} bind:pw bind:showsInitialPasswordChangePage />
    {:else if showsPasswordResetPage}
      <!-- パスワードリセット -->
      <PasswordReset bind:id bind:pw bind:showsPasswordResetPage />
    {:else}
      <!-- 通常ログイン -->
      <NormalLogin
        bind:id
        bind:pw
        bind:showsInitialPasswordChangePage
        bind:showsPasswordResetPage
      />
    {/if}
  </div>
</main>

<style lang="scss">
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1.4;
    overflow: auto;
    background-color: #3a659a;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    margin: auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;

    :global(.heading .title) {
      font-size: larger;
      font-weight: bold;
    }

    :global(.pageOverview) {
      margin-top: 20px;
      line-height: 20px;
    }

    :global(.inputField) {
      :global(.mdc-text-field) {
        width: 280px;
        background-color: #fff;
      }
    }

    :global(.passwordField) {
      :global(.mdc-icon-button) {
        margin: auto 0;
      }
    }

    :global(.errorMessage) {
      margin-top: 8px;
      color: rgb(228, 4, 4);
      font-size: 14px;
      font-weight: 600;
    }

    :global(.passwordStrengthChecker) {
      margin-top: 5px;
    }

    :global(.command) {
      margin-top: 30px;
    }
  }
</style>

export const textIncludeFilter = ({ filterValue, value }) => {
  return (
    String(value).toLowerCase().indexOf(String(filterValue).toLowerCase()) !==
    -1
  );
};

export const matchFilter = ({ filterValue, value }) => {
  if (filterValue === undefined) return true;
  return filterValue === value;
};

<script>
  import { PasswordStrength } from "~/libs/constants";

  /** @type {string} */
  export let password;

  /** @type {PasswordStrength} */
  export let passwordStrength;

  const INDICATORS = {
    [PasswordStrength.WEAK]: { width: "33%", color: "#e40808" },
    [PasswordStrength.MEDIUM]: { width: "66%", color: "#ffd800" },
    [PasswordStrength.STRONG]: { width: "100%", color: "#222eed" },
  };

  $: indicator = INDICATORS[(passwordStrength = calculateStrength(password))];

  /**
   * @type {{
   *   length: boolean,
   *   usedCharTypes: boolean,
   * }}
   */
  let strengthStatus = { length: false, usedCharTypes: false };

  function calculateStrength(password) {
    const numberOfUsedCharTypes =
      Number(/[A-Z]/.test(password)) +
      Number(/[a-z]/.test(password)) +
      Number(/[0-9]/.test(password)) +
      Number(/[^a-zA-Z0-9]/.test(password));

    strengthStatus.length = password.length >= 8;
    strengthStatus.usedCharTypes = numberOfUsedCharTypes >= 3;

    if (strengthStatus.length) {
      return strengthStatus.usedCharTypes
        ? PasswordStrength.STRONG
        : PasswordStrength.MEDIUM;
    }
    return PasswordStrength.WEAK;
  }
</script>

<div class="indicator">
  {#if password.length > 0}
    <div
      style:width={indicator.width}
      style:background-color={indicator.color}
    ></div>
  {:else}
    <div></div>
  {/if}
</div>

{#if password.length > 0}
  <div class="messages">
    {#if !strengthStatus.length}
      <div>
        <p>✖</p>
        <p>8文字以上必要です</p>
      </div>
    {/if}
    {#if !strengthStatus.usedCharTypes}
      <div>
        <p>✖</p>
        <p>
          英小文字(a～z)、英大文字(A～Z)、数字、記号から3種類以上使ってください
        </p>
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  .indicator {
    width: 280px;
    height: 4px;
    background-color: #ccc;
    margin: 0 auto;

    div {
      height: 100%;
    }
  }

  .messages {
    margin: 3px 0 0 2px;

    > div {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 1px;

      p {
        font-size: 14px;
        color: rgb(228, 4, 4);
      }
      p:first-child {
        padding-right: 1px;
      }
    }
  }
</style>

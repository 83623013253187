<script>
  import Button from "@smui/button";
  import Dialog, { Content, Title } from "@smui/dialog";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import CloseIcon from "~/components/icons/close.svelte";
  import backendApi from "~/libs/backendApi";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let maskedDetailedShipment;

  /** @type {Function} */
  export let confirmMisdeliveryCancelDialog;

  let open;

  /**
   * キャンセル画面を開く。
   */
  export function dialogOpen() {
    open = true;
  }

  /**
   * キャンセル画面を閉じる。
   */
  function closeHandler() {
    open = false;
  }

  /**
   * 登録ボタンを押したときの処理
   */
  async function confirmHandler() {
    try {
      await execStatusUpdateApi();
      confirmMisdeliveryCancelDialog();
      toast.info($_("message.updateComplete"));
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.updateUnauthorized.message"));
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        console.error(error);
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
    open = false;
  }

  const execStatusUpdateApi = loadingProgress.wrapAsync(async () => {
    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} */
    const updateShipmentEvent = {
      trackingNumber: maskedDetailedShipment.trackingNumber,
      status: maskedDetailedShipment.status,
      version: maskedDetailedShipment.version,
    };
    updateShipmentEvent.misdelivered = false;

    return await backendApi.updateShipment(updateShipmentEvent);
  });
</script>

<div class="dialogWrapper">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={closeHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 101;"
  >
    <Title id="cancel-dialog-title">
      <p>誤送解除確認</p>
      <div class="closeButtonArea">
        <button class="closeButton" on:click={closeHandler}>
          <CloseIcon /></button
        >
      </div>
    </Title>
    <Content id="detail-dialog-content">
      <div class="noteArea">
        <p>この荷物の<b>配達不能（誤送）</b>状態を解除します。</p>
        <p>
          「通信欄(配送事業者内)」欄は自動で削除されないため、必要に応じて該当する内容の削除を行ってください。
        </p>
      </div></Content
    >
    <div class="buttonArea">
      <Button touch variant="unelevated" on:click={confirmHandler}
        >配達不能（誤送）状態を解除する</Button
      >
    </div>
  </Dialog>
</div>

<style lang="scss">
  .dialogWrapper {
    :global(#cancel-dialog-title) {
      display: flex;
      padding-top: 20px;
    }
  }
  .closeButtonArea {
    margin: 0 0 0 auto;
    button {
      padding: 4px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 50%;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .buttonArea {
    display: flex;
    justify-content: end;
    margin: 0 16px 8px 0;
  }
</style>

<script>
  import Tooltip, { Content, Wrapper } from "@smui/tooltip";
  import { HTTPError } from "ky";
  import { onDestroy } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { _ } from "svelte-i18n";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import backendApi from "~/libs/backendApi";
  import { messageDialogClose, uploadResultData } from "~/libs/stores";
  import { desiredTimeFormat } from "~/libs/utils";
  import UploadDataTableEditButton from "~/pages/Upload/UploadDataTableEditButton.svelte";

  export let results;
  (() => {
    uploadResultData.set(results);
  })();
  export let registerFinished;
  $: if (registerFinished) {
    uploadResultData.set(results);
  }
  export let countingNumberOfRecords;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;

  /** @type {boolean} */
  export let supportCashOnDelivery;

  /** @type {string} */
  export let formatType;

  const table = createTable(uploadResultData);

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "編集",
          id: "editButton",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(UploadDataTableEditButton, {
              record: dataCell.value,
              registerFinished: registerFinished,
              supportCashOnDelivery: supportCashOnDelivery,
              postEditingFunction: verificationAfterEditing,
              formatType: formatType,
            }),
        }),
        table.column({
          header: "注文番号",
          id: "customerOrderId",
          accessor: (item) => item.customerOrderId ?? "",
        }),
        table.column({
          header: "送り状番号",
          id: "trackingNumber",
          accessor: (item) => item.trackingNumber ?? "",
        }),
        table.column({
          header: "届け先郵便番号",
          id: "receiverPostcode",
          accessor: (item) => item.receiverPostcode ?? "",
        }),
        table.column({
          header: "届け先住所1",
          id: "receiverAddress1",
          accessor: (item) => item.receiverAddress1 ?? "",
        }),
        table.column({
          header: "届け先住所2",
          id: "receiverAddress2",
          accessor: (item) => item.receiverAddress2 ?? "",
        }),
        table.column({
          header: "届け先名",
          id: "receiverName",
          accessor: (item) => item.receiverName ?? "",
        }),
        table.column({
          header: "届け先電話番号",
          id: "receiverTel",
          accessor: (item) => item.receiverTel ?? "",
        }),
        table.column({
          header: "届け先Eメールアドレス",
          id: "receiverEmailAddress",
          accessor: (item) => item.receiverEmailAddress ?? "",
        }),
        table.column({
          header: "依頼主郵便番号",
          id: "shipperPostcode",
          accessor: (item) => item.shipperPostcode ?? "",
        }),
        table.column({
          header: "依頼主住所1",
          id: "shipperAddress1",
          accessor: (item) => item.shipperAddress1 ?? "",
        }),
        table.column({
          header: "依頼主住所2",
          id: "shipperAddress2",
          accessor: (item) => item.shipperAddress2 ?? "",
        }),
        table.column({
          header: "依頼主名",
          id: "shipperName",
          accessor: (item) => item.shipperName ?? "",
        }),
        table.column({
          header: "依頼主電話番号",
          id: "shipperTel",
          accessor: (item) => item.shipperTel ?? "",
        }),
        table.column({
          header: "依頼主備考１",
          id: "shipperRemarks1",
          accessor: (item) => item.shipperRemarks1 ?? "",
        }),
        table.column({
          header: "依頼主備考２",
          id: "shipperRemarks2",
          accessor: (item) => item.shipperRemarks2 ?? "",
        }),
        table.column({
          header: "希望配達日",
          id: "desiredDate",
          accessor: (item) => item.desiredDate ?? "",
        }),
        table.column({
          header: "希望配達時間",
          id: "desiredTime",
          accessor: (item) =>
            item.desiredTime ? desiredTimeFormat(item.desiredTime) : "",
        }),
        table.column({
          header: "希望配達方法",
          id: "packageDropPlace",
          accessor: (item) =>
            item.packageDropPlace
              ? $_(`classes.packageDropPlace.${item.packageDropPlace}`)
              : "",
        }),
      ];
      if (supportCashOnDelivery) {
        columns.push(
          table.column({
            header: "代引金額",
            id: "cashOnDeliveryAmount",
            accessor: (item) => item.cashOnDeliveryAmount ?? 0,
          }),
        );
      }
      columns.push(
        table.column({
          header: "個数",
          id: "numberOfPackages",
          accessor: (item) => item.numberOfPackages ?? "",
        }),
      );
      if (formatType === "2") {
        columns.push(
          table.column({
            header: "配達時注意事項",
            id: "deliveryPrecautions",
            accessor: (item) => item.deliveryPrecautions ?? "",
          }),
        );
      }
      return columns;
    })(),
  );

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);

  /**
   * 編集後データの検証を行う。
   * @param {object} inputData
   */
  async function verificationAfterEditing(inputData) {
    results[inputData.index].customerOrderId = inputData.customerOrderId;
    results[inputData.index].trackingNumber = inputData.trackingNumber;
    results[inputData.index].receiverPostcode = inputData.receiverPostcode;
    results[inputData.index].receiverAddress1 = inputData.receiverAddress1;
    results[inputData.index].receiverAddress2 = inputData.receiverAddress2;
    results[inputData.index].receiverName = inputData.receiverName;
    results[inputData.index].receiverTel = inputData.receiverTel;
    results[inputData.index].receiverEmailAddress =
      inputData.receiverEmailAddress;
    results[inputData.index].shipperPostcode = inputData.shipperPostcode;
    results[inputData.index].shipperAddress1 = inputData.shipperAddress1;
    results[inputData.index].shipperAddress2 = inputData.shipperAddress2;
    results[inputData.index].shipperName = inputData.shipperName;
    results[inputData.index].shipperTel = inputData.shipperTel;
    results[inputData.index].shipperRemarks1 = inputData.shipperRemarks1;
    results[inputData.index].shipperRemarks2 = inputData.shipperRemarks2;
    results[inputData.index].desiredDate = inputData.desiredDate.replace(
      /-/g,
      "/",
    );
    results[inputData.index].desiredTime = inputData.desiredTime;
    results[inputData.index].packageDropPlace = inputData.packageDropPlace;
    if (supportCashOnDelivery) {
      results[inputData.index].cashOnDeliveryAmount =
        inputData.cashOnDeliveryAmount;
    }
    results[inputData.index].numberOfPackages = inputData.numberOfPackages;
    results[inputData.index].deliveryPrecautions =
      inputData.deliveryPrecautions;
    verify(results[inputData.index]);
  }

  const verify = async (record) => {
    try {
      await backendApi.verifyShipment(record);
      delete record.violations;
      uploadResultData.set(results);
      countingNumberOfRecords();
    } catch (error) {
      /** @type {import("~/libs/backendApi").ErrorResponse} */
      const errorResponse = error["errorResponse"];

      if (errorResponse?.details?.violations) {
        delete record.violations;
        for (const violation of errorResponse.details.violations) {
          if (record.violations) {
            if (record.violations.get(violation.path)) {
              // すでに同じ行＆項目のエラーがある場合はメッセージを追加
              const messageMap = record.violations.get(violation.path);
              messageMap.push({
                level: violation.level,
                message: violation.message,
              });
            } else {
              // すでに同じ行のエラーがある場合は項目とメッセージを追加
              record.violations.set(
                violation.path,
                Array({
                  level: violation.level,
                  message: violation.message,
                }),
              );
            }
          } else {
            // まだ同じ行のエラーがない場合は新規追加
            const violations = new Map();
            violations.set(
              violation.path,
              Array({
                level: violation.level,
                message: violation.message,
              }),
            );
            record.violations = violations;
          }
        }
        results = results;
        uploadResultData.set(results);
        countingNumberOfRecords();
      } else {
        console.error(error);
        showErrorMessage(error);
      }
    }
  };

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HTTPError && error.response?.status == 401) {
      displayMessageDialog(
        $_("errors.unauthorized.title"),
        $_("errors.unauthorized.message"),
      );
    } else if (error instanceof HTTPError && error.response?.status == 403) {
      displayMessageDialog(
        $_("errors.forbidden.title"),
        $_("errors.forbidden.message"),
      );
    } else {
      displayMessageDialog(
        $_("errors.defaultMessage.title"),
        $_("errors.defaultMessage.message"),
      );
    }
  }

  onDestroy(() => {
    messageDialogCloseUnsubscriber?.();
  });

  const messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  function displayMessageDialog(title, message) {
    dialogTitle = title;
    dialogMessage = message;
    dialogComponent = MessageDialog;
  }
</script>

<div class="uploadDataTable mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table" {...$tableAttrs}>
      <thead {...$tableHeadAttrs}>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr class="mdc-data-table__header-row" {...rowAttrs}>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe attrs={cell.attrs()} let:attrs>
                  <th
                    class="mdc-data-table__header-cell"
                    {...attrs}
                    style="text-align: center;"
                  >
                    <Render of={cell.render()} /></th
                  >
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
        {#each $rows as row (row.id)}
          {#if results[row.id].display}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
              <tr
                class="mdc-data-table__row"
                {...rowAttrs}
                style={results[row.id].violations
                  ? `background-color: lightyellow;`
                  : ""}
              >
                {#each row.cells as cell (cell.id)}
                  <Subscribe attrs={cell.attrs()} let:attrs>
                    <td
                      class="mdc-data-table__cell"
                      {...attrs}
                      class:cashOnDeliveryAmount={cell.id ===
                        "cashOnDeliveryAmount"}
                      style={results[row.id].violations?.get(cell.id)
                        ? `background-color: pink;`
                        : ""}
                    >
                      <Wrapper rich>
                        <div class="tooltip-eventer">
                          {#if cell.id === "cashOnDeliveryAmount"}
                            {cell.render() !== "0"
                              ? `${Number(cell.render()).toLocaleString()}円`
                              : ""}
                          {:else}
                            <Render of={cell.render()} />
                          {/if}
                        </div>
                        {#if results[row.id].violations?.get(cell.id)}
                          <Tooltip
                            style="top: unset; left: unset; bottom: 55px; right: 0;"
                          >
                            <Content>
                              {#each results[row.id].violations.get(cell.id) as violation}
                                {violation.message} <br />
                              {/each}
                            </Content>
                          </Tooltip>
                        {/if}
                      </Wrapper>
                    </td>
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/if}
        {/each}
      </tbody>
    </table>
  </div>
</div>
<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />

<style lang="scss">
  .uploadDataTable {
    :global(.mdc-data-table__cell) {
      overflow: unset;
      padding: 0;
    }
    :global(.mdc-tooltip-wrapper--rich .tooltip-eventer) {
      box-sizing: border-box;
      padding: 15.5px 16px;
      height: 51px;
    }
    :global(
        .mdc-data-table__row
          .mdc-data-table__cell:first-of-type
          .mdc-tooltip-wrapper--rich
          .tooltip-eventer
      ) {
      padding: 10.5px 16px;
    }
  }
  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 220px);
    overflow-x: auto;
    overflow-y: visible;
  }

  th {
    background-color: #eaf5ff;
  }
  th,
  td {
    vertical-align: middle;
    font-size: small;
  }
  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }

  .cashOnDeliveryAmount {
    text-align: right;
  }
</style>

<script>
  import Button from "@smui/button";
  import Dialog, { Content, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import Textfield from "@smui/textfield";
  import { HTTPError } from "ky";
  import { _ } from "svelte-i18n";

  import PasswordStrengthChecker from "~/components/PasswordStrengthChecker.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { PasswordStrength } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** ダイアログの開閉フラグ @type {boolean} */
  export let open;

  /** 現在のパスワード */
  let currentPw = "";
  /** 現在のパスワードのtype属性値 */
  let currentPwType = "password";
  /** 新しいパスワード */
  let newPw1 = "";
  /** 新しいパスワードのtype属性値 */
  let newPw1Type = "password";
  /** 新しいパスワード（再入力） */
  let newPw2 = "";
  /** 新しいパスワード（再入力）のtype属性値 */
  let newPw2Type = "password";

  /** 新しいパスワードの強度 @type {PasswordStrength} */
  let passwordStrength;

  /** 「パスワードを変更」ボタンの有効化フラグ */
  $: updateButtonDisabled = !(
    currentPw &&
    newPw1 &&
    newPw2 &&
    passwordStrength === PasswordStrength.STRONG &&
    newPw1 === newPw2
  );

  function assertNewPassword() {
    if (newPw1 !== newPw2) {
      throw new HandledError("", $_("errors.mismatchPassword.message"));
    }
    if (newPw1.length < 8) {
      throw new HandledError("", $_("errors.weakPassword.message"));
    }
    if (currentPw === newPw1) {
      throw new HandledError("", $_("errors.matchOldPassword.message"));
    }
  }

  const changePassword = loadingProgress.wrapAsync(async () => {
    try {
      assertNewPassword();
      await backendApi.changePassword({
        oldPassword: currentPw,
        newPassword: newPw1,
      });
      toast.info($_("message.passwordChangeComplete"));
      open = false;
    } catch (error) {
      showErrorToast(error);
    }
  });

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error instanceof HandledError) {
      toast.error(error.message);
    } else if (error instanceof HTTPError && error.response?.status == 401) {
      toast.error($_("errors.changePasswordUnauthorized.message"));
    } else if (
      error instanceof HTTPError &&
      error.response?.status >= 400 &&
      error.response?.status < 500
    ) {
      toast.error($_("errors.changePasswordInvalidRequest.message"));
    } else {
      toast.error($_("errors.updateDefaultMessage.message"));
    }
  }

  /**
   * 入力フィールドでEnterキーを押下した時に「更新」ボタンの処理を実行するイベントハンドラ。
   * @param {Event} event
   */
  function onEnterKeyDownHandler(event) {
    if (
      /** @type {KeyboardEvent} */ (event).key === "Enter" &&
      !updateButtonDisabled
    ) {
      changePassword();
    }
  }
</script>

<Dialog bind:open style="max-height: 90%; position: absolute; z-index: 1000;">
  <Title
    style="border-bottom: solid; border-width: 2px; border-color: rgb(220, 220, 220); display: flex; padding-top: 20px;"
  >
    <p>パスワード変更</p>
    <div class="closeButtonArea">
      <button
        class="closeButton"
        on:click={() => {
          open = false;
        }}><span class="material-icons">close</span></button
      >
    </div>
  </Title>

  <Content>
    <div class="formArea">
      <div class="inputColumn">
        <Textfield
          type={currentPwType}
          label="現在のパスワード"
          variant="outlined"
          class="inputText"
          required
          input$id="current-password"
          input$name="current-password"
          input$autocomplete="current-password"
          bind:value={currentPw}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              currentPwType = currentPwType == "text" ? "password" : "text";
            }}
          >
            {currentPwType == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>
        <Textfield
          type={newPw1Type}
          label="新しいパスワード"
          variant="outlined"
          class="inputText"
          required
          input$id="new-password"
          input$name="new-password"
          input$autocomplete="new-password"
          bind:value={newPw1}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              newPw1Type = newPw1Type == "text" ? "password" : "text";
            }}
          >
            {newPw1Type == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>
        <Textfield
          type={newPw2Type}
          label="新しいパスワード（再入力）"
          variant="outlined"
          class="inputText"
          required
          input$id="new-password-2"
          input$name="new-password"
          input$autocomplete="new-password"
          bind:value={newPw2}
          on:keydown={onEnterKeyDownHandler}
        >
          <IconButton
            slot="trailingIcon"
            class="material-icons md-dark"
            tabindex={-1}
            on:click={() => {
              newPw2Type = newPw2Type == "text" ? "password" : "text";
            }}
          >
            {newPw2Type == "text" ? "visibility_off" : "visibility"}
          </IconButton>
        </Textfield>

        <div class="passwordStrengthChecker">
          <PasswordStrengthChecker password={newPw1} bind:passwordStrength />
        </div>
      </div>

      <div class="submitArea">
        <Button
          variant="raised"
          style="width: 170px;"
          on:click={changePassword}
          bind:disabled={updateButtonDisabled}>パスワードを変更</Button
        >
      </div>
    </div>
  </Content>
</Dialog>

<style lang="scss">
  .closeButtonArea {
    margin: 0 0 0 auto;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    button:focus {
      outline: 0;
    }

    .material-icons {
      font-size: 30px;
    }
  }

  .formArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 280px;
    padding: 25px 0 20px;
  }

  .inputColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    .passwordStrengthChecker {
      margin-top: -15px;
    }

    :global(.inputText) {
      width: 100%;
    }
  }

  .submitArea {
    text-align: center;
  }
</style>

<script>
  import { HTTPError } from "ky";
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import KnowledgeTable from "~/pages/Knowledge/KnowledgeTable.svelte";
  import KnowledgeUpdateDialog from "~/pages/Knowledge/KnowledgeUpdateDialog.svelte";

  let updateDialogComponent;
  let tableComponent;
  /**
   * @type {string}
   * ナレッジ一覧エリアの表示状態
   */
  let resultAreaDisplay = "none";
  /**
   * @type {string}
   * データ取得失敗コメントの表示状態
   */
  let failedDataCommentDisplay = "none";
  /**
   * ナレッジ取得APIの実行結果を格納する変数
   */
  let results;
  /**
   * 更新対象のナレッジデータを格納する変数
   * @type {import("~/libs/commonTypes").ShippingKnowledge}
   */
  let targetResult;

  onDestroy(() => {
    dialogCloseHandler();
  });

  /**
   * ナレッジ取得APIを実行する。
   * @returns {Promise<Array<import("~/libs/commonTypes").ShippingKnowledge>>}
   */
  const execKnowledgeApi = async () => {
    const response = await backendApi.getShippingKnowledge();
    return response;
  };

  /**
   * ナレッジ一覧を取得する。
   * @returns {Promise<void>}
   */
  const getShippingKnowledgeList = loadingProgress.wrapAsync(async () => {
    /** @type {string} */
    let message;

    failedDataCommentDisplay = "none";
    try {
      results = await execKnowledgeApi();
      tableComponent = KnowledgeTable;
    } catch (error) {
      if (error instanceof HandledError) {
        message = error.message;
      } else {
        if (error instanceof HTTPError && error.response?.status == 401) {
          message = $_("errors.updateUnauthorized.message");
        } else if (
          error instanceof HTTPError &&
          error.response?.status == 403
        ) {
          message = $_("errors.updateForbidden.message");
        } else {
          message = $_("errors.updateDefaultMessage.message");
        }
      }
      failedDataCommentDisplay = "block";
      toast.error(message);
    } finally {
      resultAreaDisplay = "block";
    }
  });

  /**
   * ナレッジ更新ダイアログを開く。
   * @param {import("~/libs/commonTypes").ShippingKnowledge} target
   */
  function dialogOpenHandler(target) {
    if (target) {
      targetResult = target;
      updateDialogComponent = KnowledgeUpdateDialog;
    }
  }

  /**
   * 更新対象のナレッジデータを取得する。
   * @param {CustomEvent<import("~/libs/commonTypes").ShippingKnowledge>} event
   */
  function handleCustomEvent(event) {
    dialogOpenHandler(event.detail);
  }

  /**
   * ダイアログコンポーネントを削除する。
   */
  function dialogCloseHandler() {
    targetResult = null;
    updateDialogComponent = null;
  }

  /**
   * ナレッジ一覧から削除されたナレッジを取り除く。
   * @param {import("~/libs/commonTypes").ShippingKnowledge} updatedResult
   */
  async function deletedResultsReplace(updatedResult) {
    let targetIndex;
    for (targetIndex = 0; targetIndex <= results.length; targetIndex++) {
      if (results[targetIndex].id === updatedResult.id) {
        break;
      }
    }
    results.splice(targetIndex, 1);
    results = results;
  }

  /**
   * ナレッジ一覧に更新内容を反映する。
   * @param {import("~/libs/commonTypes").ShippingKnowledge} updatedResult
   */
  async function updatedResultsReplace(updatedResult) {
    let targetIndex;
    for (targetIndex = 0; targetIndex <= results.length; targetIndex++) {
      if (results[targetIndex].id === updatedResult.id) {
        break;
      }
    }
    results.splice(targetIndex, 1, updatedResult);
    results = results;
  }

  onMount(() => {
    getShippingKnowledgeList();
  });
</script>

<svelte:component
  this={updateDialogComponent}
  {targetResult}
  {dialogCloseHandler}
  {updatedResultsReplace}
/>
<div class="wrapper">
  <!-- タイトル -->
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">共有配達メモ管理</h1>
  </div>

  <!-- 一覧 -->
  <div class="resultArea" style="display: {resultAreaDisplay};">
    <div id="datatable">
      <div id="failedDataComment" style="display: {failedDataCommentDisplay};">
        データ取得に失敗しました。
      </div>
      {#key results}
        <svelte:component
          this={tableComponent}
          {results}
          {deletedResultsReplace}
          on:dialogOpen={handleCustomEvent}
        />
      {/key}
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: 96%;
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }

  .titleArea {
    height: 40px;
    display: flex;
  }

  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }

  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }

  .resultArea {
    width: 100%;
    min-width: 822px;
    height: fit-content;
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 5px;
    margin-top: 16px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }

  #failedDataComment {
    margin: 10px;
    font-size: smaller;
  }
</style>

<script>
  import Button from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { onMount } from "svelte";

  import { managementForNewUserClose } from "~/libs/stores";

  export let addedLoginData;

  let open;

  onMount(openDialog);

  function openDialog() {
    open = true;
  }

  function handleCopyInfomation() {
    navigator.clipboard
      .writeText(
        "ユーザーID：" +
          addedLoginData.userName +
          "\nパスワード：" +
          addedLoginData.password,
      )
      .then(
        () => {
          alert("ユーザーIDとパスワードをコピーしました。");
        },
        () => {
          alert("コピーに失敗しました。");
        },
      );
  }

  function closeHandler() {
    managementForNewUserClose.set(true);
  }
</script>

<Dialog bind:open on:SMUIDialog:closed={closeHandler} style="z-index: 100;">
  <Title>登録完了</Title>
  <Content>
    <p class="caption">
      新規ユーザーの追加が完了しました。<br />
      ログイン時には以下の情報をご本人にお伝えください。
    </p>
    <div class="item">
      <div class="itemName">ユーザーID</div>
      <div class="itemLine">
        <p>{addedLoginData.userName}</p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">パスワード</div>
      <div class="itemLine">
        <p>{addedLoginData.password}</p>
      </div>
    </div>
    <Actions>
      <Button on:click={handleCopyInfomation}>クリップボードにコピーする</Button
      >
      <Button style="background-color: #D9D9D9; color: #333;" action={"cancel"}
        >閉じる</Button
      >
    </Actions>
  </Content>
</Dialog>

<style>
  .caption {
    color: #333;
    margin-bottom: 30px;
  }
  .item {
    display: flex;
    position: relative;
    width: 500px;
    margin: 8px auto;
    border-bottom: 1px solid #eee;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 150px;
    height: 30px;
    margin: 0 10px 8px 0;
    padding-top: 8px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemLine {
    width: 350px;
    display: flex;
    justify-content: space-between;
  }
  .itemLine > p {
    color: #333;
    padding-top: 8px;
  }
</style>

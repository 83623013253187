<script>
  import Button from "@smui/button";

  import EditDialog from "~/pages/Upload/UploadEditDialog.svelte";

  export let record;
  export let registerFinished;
  export let supportCashOnDelivery;
  export let postEditingFunction;
  export let formatType;

  /** 編集ダイアログのコンポーネント */
  let editDialog;

  function edit() {
    editDialog = EditDialog;
  }

  function closeHandlerAction(action, inputData) {
    switch (action) {
      case "save":
        postEditingFunction(inputData);
        break;
      case "cancel":
        break;
    }
    editDialog = null;
  }
</script>

<Button
  style="height: 30px; width: 60px; margin: 0 5px;"
  touch
  variant="unelevated"
  on:click={edit}
  disabled={registerFinished}>編集</Button
>
<svelte:component
  this={editDialog}
  {record}
  {supportCashOnDelivery}
  {formatType}
  {closeHandlerAction}
/>

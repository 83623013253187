<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { onMount } from "svelte";

  import { messageDialogClose } from "~/libs/stores";

  export let dialogTitle;
  export let dialogMessage;

  let dialogMessages = [];
  let includingIndention = false;
  let open;

  onMount(async () => {
    let result = dialogMessage.indexOf("<br />", 0);
    if (result) {
      console.log(result);
      includingIndention = true;
      dialogMessages = dialogMessage.split("<br />");
    }
    open = true;
  });

  function closeHandler() {
    messageDialogClose.set(true);
  }
</script>

<Dialog
  bind:open
  aria-labelledby="title"
  aria-describedby="message"
  on:SMUIDialog:closed={closeHandler}
  style="z-index: 100;"
>
  <Title id="title">{dialogTitle}</Title>
  <Content id="message" style="color: rgba(0, 0, 0, .7)">
    {#if includingIndention}
      {#each dialogMessages as message}
        {message}<br />
      {/each}
    {:else}
      {dialogMessage}
    {/if}
  </Content>
  <Actions>
    <Button>
      <Label>OK</Label>
    </Button>
  </Actions>
</Dialog>

<script>
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { getContext, setContext } from "svelte";
  import {
    addMessages,
    getLocaleFromNavigator,
    init as i18nInit,
  } from "svelte-i18n";
  import Router from "svelte-spa-router";

  import backendApi from "~/libs/backendApi";
  import { AppContext, UserContext } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    TOAST_DURATION,
  } from "~/libs/constants";
  import localStorages from "~/libs/localStorages";
  import i18n_ja from "~/locales/ja.json";
  import Login from "~/pages/Login.svelte";
  import Home from "~/pages/Main.svelte";

  // 全ページで共有するアプリケーションのコンテキスト情報を初期化（Context APIでアクセス）
  try {
    // アプリケーション固有のコンテキスト情報
    const appContext = new AppContext();
    if (localStorages.appContext) {
      const storedAppContext = JSON.parse(localStorages.appContext);
      Object.assign(appContext, storedAppContext);
    }
    setContext(CONTEXT_KEY_APP, appContext);

    // ユーザ固有のコンテキスト情報
    const userContext = new UserContext();
    if (localStorages.userContext) {
      const storedUserContext = JSON.parse(localStorages.userContext);
      Object.assign(userContext, storedUserContext);
    }
    setContext(CONTEXT_KEY_USER, userContext);

    window.addEventListener("beforeunload", () => {
      // Windowが閉じられる前に、コンテキスト情報をlocalStorageに保存
      userContext.store();
    });

    console.log(userContext);
  } catch (error) {
    console.error(error);
  }

  addMessages("ja", i18n_ja);
  i18nInit({
    fallbackLocale: "ja",
    initialLocale: getLocaleFromNavigator(),
  });

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  // kyの初期化
  backendApi.initialize(userContext);

  const routes = {
    "/": Login,
    "/home": Home,
    "*": Login,
  };
</script>

<Router {routes} />

<SvelteToast
  options={{
    duration: TOAST_DURATION,
    pausable: false,
  }}
/>

<script>
  import Button from "@smui/button";
  import LinearProgress from "@smui/linear-progress";
  import { HTTPError } from "ky";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import backendApi from "~/libs/backendApi";
  import { searchConditionType } from "~/libs/constants";
  import {
    editSearchResult,
    searchResultDialogClose,
    searchResultUpdateClose,
    searchResultUpdateOpen,
    targetSearchResult,
  } from "~/libs/stores";
  import { messageDialogClose } from "~/libs/stores";
  import SearchResultDialog from "~/pages/Search/SearchResultDialog.svelte";
  import SearchResultTable from "~/pages/Search/SearchResultTable.svelte";
  import SearchResultUpdate from "~/pages/Search/SearchResultUpdate.svelte";
  import { setShippingAndReturnStatus } from "~/pages/Search/SearchUtil";

  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} */
  export let results = [];
  /** @type {import("~/libs/constants").searchConditionType} */
  export let searchedConditionType;
  /** @type {import("~/libs/backendApi").SearchShipmentRequest | import("~/libs/backendApi").SearchShipmentByLocationIdRequest}*/
  export let searchedCondition;
  /** @type {number} */
  export let count;
  /** @type {number} */
  export let disabledCount;
  /** @type {boolean} */
  export let maxLimitOver;
  /** @type {Map<number, object>}*/
  export let centersMap;

  /** @type {string} */
  let dialogTitle;
  /** @type {string} */
  let dialogMessage;
  let dialogComponent;
  /** @type {import("~/libs/backendApi").SearchedShipment} */
  let result;
  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;
  /** @type {import("svelte/store").Unsubscriber} */
  let searchResultDialogCloseUnsubscribe;
  /** @type {import("svelte/store").Unsubscriber} */
  let searchResultUpdateCloseUnsubscribe;
  /** @type {import("svelte/store").Unsubscriber} */
  let searchResultUpdateOpenUnsubscribe;
  /** @type {import("svelte/store").Unsubscriber} */
  let targetSearchResultUnsubscribe;
  let progressBarDisplay = "none";
  /** @type {number} */
  let filterResultsNum = count - disabledCount;

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  onDestroy(() => {
    messageDialogCloseUnsubscriber?.();
    searchResultDialogCloseUnsubscribe?.();
    searchResultUpdateCloseUnsubscribe?.();
    searchResultUpdateOpenUnsubscribe?.();
    targetSearchResultUnsubscribe?.();
  });

  function displayMessageDialog(title, message) {
    dialogTitle = title;
    dialogMessage = message;
    dialogComponent = MessageDialog;
  }

  function createFileName() {
    let now = new Date();
    let filename = "searchResult_";
    filename += now.getFullYear() + "-";
    filename += now.getMonth() + 1 + "-";
    filename += now.getDate() + "-";
    filename += now.getHours() + "-";
    filename += now.getMinutes() + "-";
    filename += now.getSeconds() + ".csv";
    return filename;
  }

  function downloadComplete() {
    displayMessageDialog(
      "ダウンロード完了",
      "CSVファイルのダウンロードが完了しました。",
    );
  }

  const download = async () => {
    progressBarDisplay = "block";
    try {
      let blob;
      if (
        searchedConditionType === searchConditionType.ALL ||
        searchedConditionType === searchConditionType.EC
      ) {
        blob =
          await backendApi.searchAndDownloadShipmentsCsv(searchedCondition);
      } else if (searchedConditionType === searchConditionType.CENTER) {
        blob =
          await backendApi.searchAndDownloadShipmentsCsvByLocationId(
            searchedCondition,
          );
      } else {
        return;
      }
      let csvname = createFileName();
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      let download = document.createElement("a");
      download.href = url;
      download.download = await csvname;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);

      downloadComplete();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      progressBarDisplay = "none";
    }
  };

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HTTPError && error.response?.status == 401) {
      displayMessageDialog(
        $_("errors.unauthorized.title"),
        $_("errors.unauthorized.message"),
      );
    } else if (error instanceof HTTPError && error.response?.status == 403) {
      displayMessageDialog(
        $_("errors.forbidden.title"),
        $_("errors.forbidden.message"),
      );
    } else {
      displayMessageDialog(
        $_("errors.defaultMessage.title"),
        $_("errors.defaultMessage.message"),
      );
    }
  }

  targetSearchResultUnsubscribe = targetSearchResult.subscribe((target) => {
    if (target) {
      result = target;
      dialogMessage = null;
      dialogComponent = SearchResultDialog;
    }
  });

  searchResultDialogCloseUnsubscribe = searchResultDialogClose.subscribe(
    (isClose) => {
      if (isClose) {
        dialogComponent = null;
        result = null;
        dialogMessage = null;
        targetSearchResult.set(null);
        searchResultDialogClose.set(false);
      }
    },
  );

  searchResultUpdateOpenUnsubscribe = searchResultUpdateOpen.subscribe(
    (isOpen) => {
      if (isOpen) {
        searchResultUpdateOpen.set(false);
        dialogComponent = SearchResultUpdate;
      }
    },
  );

  searchResultUpdateCloseUnsubscribe = searchResultUpdateClose.subscribe(
    async (isClose) => {
      if (isClose) {
        if ($editSearchResult) {
          await updatedResultsReplace($editSearchResult);
          editSearchResult.set(null);
        }
        searchResultUpdateClose.set(false);
        dialogComponent = SearchResultDialog;
      }
    },
  );

  /**
   * 検索結果一覧に更新内容を反映する。
   * @param {import("~/libs/backendApi").SearchedShipment} updatedResult
   */
  async function updatedResultsReplace(updatedResult) {
    updatedResult.shippingAndReturnStatus =
      await setShippingAndReturnStatus(updatedResult);
    let targetIndex;
    for (targetIndex = 0; targetIndex <= results.length; targetIndex++) {
      if (
        results[targetIndex].trackingNumber ===
        $targetSearchResult.trackingNumber
      ) {
        break;
      }
    }
    results.splice(targetIndex, 1, updatedResult);
    results = results;
    result = updatedResult;
  }

  function handleMessage(event) {
    dialogMessage = event.detail.text;
  }
</script>

<svelte:component
  this={dialogComponent}
  {dialogTitle}
  {dialogMessage}
  {result}
  {updatedResultsReplace}
  on:message={handleMessage}
/>
<div id="dataComment">
  <div id="progressBar" style="display:{progressBarDisplay}">
    <LinearProgress indeterminate />
  </div>
  <div class="resultHeader">
    <h1 class="resultNumber">
      検索結果 {(count - disabledCount).toLocaleString()}件
      {#if count - disabledCount != filterResultsNum}<span
          >（内絞り込み結果 {filterResultsNum}件）</span
        >{/if}
    </h1>
    {#if !(count == disabledCount && !maxLimitOver)}
      <div class="topButtonArea">
        <Button
          style="
            position: absolute;
            right: 0;
            transform: translateX(-4px);
            height: 40px;
            width: 180px;"
          touch
          variant="unelevated"
          on:click={download}
          >ダウンロード
        </Button>
      </div>
    {/if}
  </div>
  {#if maxLimitOver}
    <p class="notes">
      検索条件に一致する配送情報が多いため表示が省略されています。
      {#if disabledCount > 0}<strong
          >（内配送誤りで非表示{disabledCount.toLocaleString()}件）</strong
        >{/if}
      <br />検索条件を絞り込むか、CSVダウンロードをおこなってください。
    </p>
  {/if}
  {#if count === disabledCount}
    <p id="noDataComment">表示対象のデータがありません。</p>
  {:else}
    <div class="searchResultTable">
      {#key results}
        <svelte:component
          this={SearchResultTable}
          {results}
          {centersMap}
          bind:filterResultsNum
        />
      {/key}
    </div>
  {/if}
</div>

<style>
  .resultNumber {
    margin: 10px 15px;
  }
  .resultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .notes {
    font-size: smaller;
    margin-left: 15px;
    margin-bottom: 4px;
    color: red;
    line-height: 17px;
  }
  #noDataComment {
    margin: 20px;
    font-size: smaller;
  }
  .topButtonArea {
    display: flex;
    position: relative;
    height: 45px;
  }
  .searchResultTable {
    max-height: calc(100vh - 220px);
    overflow-x: auto;
    overflow-y: visible;
  }
</style>

const UPPERCASE_LETTERS = "ABCDEFGHJKLMNPQRSTUVWXYZ";
const LOWERCASE_LETTERS = "abcdefghijkmnpqrstuvwxyz";
const NUMBERS = "23456789";

const LOWERCASE_LENGTH = 3;
const NUMBERS_LENGTH = 4;

/**
 * 規定のルールでランダム生成したパスワードを返す。
 * ルール：英語大文字 = 1, 英語小文字 = 3, 数字 = 4 の連結
 * @returns {string} パスワード
 */
export function generatePassword() {
  let password = UPPERCASE_LETTERS.charAt(
    Math.floor(Math.random() * UPPERCASE_LETTERS.length),
  );
  for (let i = 0; i < LOWERCASE_LENGTH; i++) {
    password += LOWERCASE_LETTERS.charAt(
      Math.floor(Math.random() * LOWERCASE_LETTERS.length),
    );
  }
  for (let i = 0; i < NUMBERS_LENGTH; i++) {
    password += NUMBERS.charAt(Math.floor(Math.random() * NUMBERS.length));
  }
  return password;
}

import { PERMIT_USER_MANAGEMENT_ROLES, ROLES } from "~/libs/constants";

export class AppContext {
  /** @type {number} */
  searchedCenterId;

  store() {
    localStorage.setItem("appContext", JSON.stringify(this));
  }
  erase() {
    localStorage.removeItem("appContext");
  }
}

/**
 * ログインユーザーに紐づくコンテキスト情報。
 */
export class UserContext {
  /**
   * @type {{
   *   username: string,
   *   roles: Array<string>,
   *   accessToken: string,
   *   expiresIn: number,
   *   loginTime: number,
   *   displayName: string,
   *   companyId: number,
   *   companyName: string,
   *   currentCompanyId: number,
   *   emailAddress: string,
   *   switchableRoles: Array<string>,
   *   switchableCompanies: Array<import("~/libs/backendApi").Company>,
   * }}
   */
  loginUser;

  /**
   * @type {{
   *   supportCashOnDelivery: boolean,
   *   maxCashOnDeliveryAmount: number,
   *   csvUploadCharset: string,
   *   csvUploadHasHeader: boolean,
   *   registrableDeliveryMethod : Array<0 | 1 | 2 | 3 | 4 | 5>,
   * }}
   */
  ecSettings;

  /**
   * ログイン要否を返す。
   * 必要なフィールドが欠損しているか、アクセストークンの期限が切れている場合はログインが必要と判断する。
   * @returns {boolean}
   */
  needsLogin() {
    return (
      !this.loginUser ||
      this.loginUser.roles?.length <= 0 ||
      !this.loginUser.accessToken ||
      this.loginUser.loginTime <= 0 ||
      this.loginUser.expiresIn <= 0 ||
      Date.now() >= this.loginUser.loginTime + this.loginUser.expiresIn * 1000
    );
  }

  /**
   * 「管理事業者／管理者」のロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasContractAdminRole() {
    return this.loginUser?.roles?.includes(ROLES.CONTRACT_ADMIN);
  }

  /**
   * 「宅配事業者／管理者」のロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasShippingPartnerAdminRole() {
    return this.loginUser?.roles?.includes(ROLES.SHIPPING_PARTNER_ADMIN);
  }

  /**
   * 「EC事業者／管理者」のロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasEcAdminRole() {
    return this.loginUser?.roles?.includes(ROLES.EC_ADMIN);
  }

  /**
   * 「SCC／運用担当者」のロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasSccOperatorRole() {
    return this.loginUser?.roles?.includes(ROLES.SCC_OPERATOR);
  }

  /**
   * ユーザーの管理が許可されたロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasPermitUserManagementRole() {
    return this.loginUser?.roles?.some((role) =>
      PERMIT_USER_MANAGEMENT_ROLES.includes(role),
    );
  }

  /**
   * ログインユーザーのユーザー名を返す。
   * @returns {string}
   */
  getUserName() {
    return this.loginUser?.username;
  }

  /**
   * 操作中ECの会社名を返す。
   * @returns {string}
   */
  getCurrentCompanyName() {
    if (this.loginUser?.switchableCompanies) {
      const company = this.loginUser?.switchableCompanies.find(
        (company) => company.id === this.loginUser?.currentCompanyId,
      );
      return company?.name;
    } else {
      return this.loginUser?.companyName;
    }
  }

  store() {
    localStorage.setItem("userContext", JSON.stringify(this));
  }
  erase() {
    for (const filedName in this) {
      delete this[filedName];
    }
    localStorage.removeItem("userContext");
  }
}

/**
 * エラーハンドリングのために使用するユーザ定義例外クラス。
 * メッセージにはユーザに表示可能なメッセージを設定する。
 */
export class HandledError extends Error {
  constructor(title, message) {
    super(message);
    this.title = title;
    this.name = "HandledError";
  }
}

/**
 * @typedef {{
 *   trackingNumber: string,
 *   status: 0 | 1 | 2 | 3 | 4,
 *   customerId: number,
 *   customerName: string,
 *   availableDeliveryMethods: Array<0 | 1 | 2 | 3 | 4 | 5>,
 *   receiverSelectableDeliveryMethod: Array<0 | 1 | 2 | 3 | 4 | 5>,
 *   signatureRequired: boolean,
 *   emailNotificationRequired: boolean,
 *   deliveryNoticeRequired: boolean,
 *   deliveryNoticeRequiredForLocker: boolean,
 *   releasedAt: string,
 *   customerOrderId: string,
 *   receiverTel: string,
 *   receiverPostcode: string,
 *   receiverAddress1: string,
 *   receiverAddress2: string,
 *   correctedReceiverAddress: string,
 *   receiverName: string,
 *   receiverEmailAddress: string,
 *   desiredDate: string,
 *   desiredTime: string,
 *   shipperTel: string,
 *   shipperPostcode: string,
 *   shipperAddress1: string,
 *   shipperAddress2: string,
 *   shipperName: string,
 *   shipperRemarks1: string,
 *   shipperRemarks2: string,
 *   numberOfPackages: number,
 *   cubicSize: number,
 *   locationShortName: string,
 *   delivererId: number,
 *   delivererName: string,
 *   driverId: number,
 *   driverUserName: string,
 *   driverDisplayName: string,
 *   createdAt: string,
 *   inTransitAt: string,
 *   heldInDepotAt: string,
 *   outForDeliveryAt: string,
 *   deliveredAt: string,
 *   inTransitLocationId: number,
 *   inTransitLocationName: string,
 *   heldInDepotLocationId: number,
 *   heldInDepotLocationName: string,
 *   outForDeliveryLocationId: number,
 *   outForDeliveryLocationName: string,
 *   unattendedDeliveryPhotoId: string,
 *   unattendedDeliveryPhotoUploaded: boolean,
 *   unattendedDeliveryPhotoUrl: string,
 *   signaturePhotoUploaded: boolean,
 *   supportTheftInsurance: boolean,
 *   supportAutoLockUnlocking: boolean,
 *   cashOnDeliveryAmount: number,
 *   packageDropPlace: 0 | 1 | 2 | 3 | 4 | 5,
 *   actualPackageDropPlace: 0 | 1 | 2 | 3 | 4 | 5,
 *   damaged: boolean,
 *   extraEvent: Array<import("~/libs/commonTypes").ExtraEvent>,
 *   deliveryBoxNumber: string,
 *   deliveryBoxPin: string,
 *   numberOfDeliveryAttempts: number,
 *   redeliveryContext: RedeliveryContext,
 *   specifiedPickupDatetime: SpecifiedPickupDatetime,
 *   returnStatus: 0 | 1 | 2 | 3,
 *   returnReason: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   requestingForReturnAt: string,
 *   waitingForReturnAt: string,
 *   waitingForReturnLocationId: number,
 *   waitingForReturnLocationName: string,
 *   returningAt: string,
 *   returnedAt: string,
 *   lost: boolean,
 *   lostAt: string,
 *   misdelivered: boolean,
 *   misdeliveryState: 0 | 1,
 *   misdeliveredAt: string,
 *   shippingPartnerInternalMessage: string,
 *   ecDelivererInternalMessage: string,
 *   delivererInternalMessage: string,
 *   version: number,
 * }} DetailedShipment
 *
 * @typedef {{
 *   time: string,
 *   extraEventType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
 * }} ExtraEvent
 *
 * @typedef {{
 *   redeliveryDatetimeSpecMethod?: number,
 *   timeFramePreset?: Array<string>,
 *   redeliveryUnavailability?: Array<DateAndTimeFrame>,
 *   adjustedRedeliveryDatetime?: DateAndTimeFrame,
 *   notificationResend?: boolean,
 * }} RedeliveryContext
 *
 * @typedef {{
 *   desiredRedeliveryDatetime: DateAndTimeFrame,
 *   availablePickupDatetime: Array<DateAndTimeFrame>,
 * }} SpecifiedPickupDatetime
 *
 * @typedef {{
 *   date: string,
 *   timeFrame: string,
 * }} DateAndTimeFrame
 *
 * @typedef {{
 *   prefecture: string,
 *   centers: Array<{id: number, name: string, locationShortName: string, latitude: number, longitude: number}>,
 * }} DepotLocation
 *
 * @typedef {{
 *   id: number,
 *   type: number,
 *   address: string,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 * }} ShippingKnowledge
 *
 * @typedef {import("~/libs/backendApi").UserInfo & {companyName?:string}} CustomedUserInfo
 */

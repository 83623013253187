import { writable } from "svelte/store";

export const activeContent = writable("Home");
export const messageDialogClose = writable(false);
export const searchResultDialogClose = writable(false);
export const searchResultUpdateClose = writable(false);
export const searchResultUpdateOpen = writable(false);
export const targetSearchResult = writable();
export const editSearchResult = writable();
export const managementResultEditClose = writable(false);
export const managementAddUserClose = writable(false);
export const managementForNewUserClose = writable(false);
export const targetTrackingNumber = writable("");
export const needReload = writable(false);
export const uploadResultData = writable();
export const searchResultData = writable();
export const knowledgeResultData = writable();

import {
  USER_KINDS_FOR_CONTRACT_ONLY,
  USER_KINDS_FOR_PARTNER,
} from "~/libs/constants";

/**
 * 選択された会社IDと会社リストを受け取り、以下情報を返す。
 * - userKindsValue：ユーザー種別ID(ユーザー種別が固定の場合のみ)
 * - userKindsList：ユーザー種別リスト(ユーザー種別が選択可能な場合のみ)
 * - userKindsDisabled：ユーザー種別が固定かどうか
 * - regulationUserKind：ユーザー種別名(ユーザー種別が固定の場合のみ)
 * @param {number} companyId 会社ID
 * @param {import("~/libs/backendApi").GetCompaniesResponse} companyList 会社リスト
 * @returns {[string, Array, boolean, string]} [userKindsValue, userKindsList, userKindsDisabled, regulationUserKind]
 */
export function getUserKindsInfo(companyId, companyList) {
  const selectedCompany = companyList.find((item) => item.id === companyId);
  let userKindsValue;
  let userKindsList = [];
  let userKindsDisabled = true;
  let regulationUserKind;
  if (selectedCompany) {
    if (selectedCompany.type === 0) {
      userKindsValue = "";
      userKindsList = USER_KINDS_FOR_CONTRACT_ONLY;
      userKindsDisabled = false;
    } else if (selectedCompany.type === 1) {
      userKindsValue = "";
      userKindsList = USER_KINDS_FOR_PARTNER;
      userKindsDisabled = false;
    } else if (selectedCompany.type === 2) {
      userKindsValue = "ec-admin";
      regulationUserKind = "EC事業者／管理者";
    } else if (selectedCompany.type === 9) {
      userKindsValue = "scc-operator";
      regulationUserKind = "システム運用事業者／運用担当者";
    }
  }
  return [userKindsValue, userKindsList, userKindsDisabled, regulationUserKind];
}

/**
 * 会社リストを受け取り、セレクトボックス用のオプションリストを返す。
 * @param {import("~/libs/backendApi").GetCompaniesResponse} companyNameList
 * @returns {[string, any[]][]} companyNameOptions
 */
export function getCompanyNameOptionGroups(companyNameList) {
  const companyNameOptionGroups = Object.entries(
    companyNameList.reduce(
      (accumulator, currentValue) => {
        if (currentValue.type === 0) {
          accumulator.管理事業者.push(currentValue);
        } else if (currentValue.type === 1) {
          accumulator.宅配事業者.push(currentValue);
        } else if (currentValue.type === 2) {
          accumulator.EC事業者.push(currentValue);
        } else if (currentValue.type === 9) {
          accumulator.システム運用事業者.push(currentValue);
        }
        // 各項目の中身を会社名でソート
        accumulator.管理事業者.sort((a, b) => a.name.localeCompare(b.name));
        accumulator.宅配事業者.sort((a, b) => a.name.localeCompare(b.name));
        accumulator.EC事業者.sort((a, b) => a.name.localeCompare(b.name));
        return accumulator;
      },
      {
        管理事業者: [],
        宅配事業者: [],
        EC事業者: [],
        システム運用事業者: [],
      },
    ),
  );
  return companyNameOptionGroups;
}

<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { onMount, tick } from "svelte";

  export let dialogTitle;
  export let photoUrl;
  /**
   * ダイアログを閉じるハンドラ
   * @type {function(): void}
   */
  export let dialogCloseHandler;

  let open;

  onMount(async () => {
    await tick();
    open = true;
  });
</script>

<Dialog
  bind:open
  aria-labelledby="title"
  aria-describedby="message"
  on:SMUIDialog:closed={dialogCloseHandler}
  style="z-index: 101;"
>
  <Title id="title">{dialogTitle}</Title>
  <Content id="message" style="color: rgba(0, 0, 0, .7)">
    <img src={photoUrl} alt={dialogTitle} />
  </Content>
  <Actions>
    <Button>
      <Label>OK</Label>
    </Button>
  </Actions>
</Dialog>

const desiredDateTime = {
  /** 受け取り可能時間帯がないことを示すtimeFrameフィールドの値 */
  UNRECEIVABLE_TIME_SLOT: "0000",

  /**
   * 初回配達の場合は配達希望日時、再配達の場合は再配達希望日時を返す。
   * 希望日時が指定されていない場合は空の配列を返す。
   *
   * redeliveryContextを登録する際はBEでspecifiedPickupDatetimeをクリアしているため、最新の情報を用いる場合は以下の順に登録されているか否かをチェックすればよい。
   * 1. `specifiedPickupDatetime`
   * 2. `redeliveryContext`
   * 3. `desiredDate` & `desiredTime`
   *
   * 【注意事項】
   * - specifiedPickupDatetime.availablePickupDatetimeが"0000"の場合、受け取り可能時間帯がないことを示している（滅茶苦茶気持ち悪い仕様なので絶対に真似しないこと）
   *
   * @param {import("~/libs/commonTypes").DetailedShipment | import("~/libs/backendApi").SearchedShipment} shipment - 配送情報
   * @returns {Array<import("~/libs/commonTypes").DateAndTimeFrame>} - 配達希望日時
   */
  resolve(shipment) {
    /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>} */
    let result = [];

    if (shipment.specifiedPickupDatetime !== undefined) {
      /* 荷受人が配達日時を指定している場合 */
      if (shipment.specifiedPickupDatetime.availablePickupDatetime) {
        // 荷受人がTrackingアプリから複数の時間帯を登録した場合
        // この場合のみ、複数の値を返す
        result = shipment.specifiedPickupDatetime.availablePickupDatetime;
      } else if (shipment.specifiedPickupDatetime.desiredRedeliveryDatetime) {
        // 荷受人がTrackingアプリから日時を１つだけ登録した場合
        result.push({
          date: shipment.specifiedPickupDatetime.desiredRedeliveryDatetime.date,
          timeFrame:
            shipment.specifiedPickupDatetime.desiredRedeliveryDatetime
              .timeFrame,
        });
      }
    } else if (shipment.redeliveryContext?.adjustedRedeliveryDatetime) {
      /* 宅配ドライバーが電話で再配達日時を調整し、Deliveryアプリに登録した場合
       * もしくは荷受人がコールセンターに電話をかけて調整した再配達日時を、コールセンター職員がAdminアプリに登録した場合 */
      result.push({
        date: shipment.redeliveryContext.adjustedRedeliveryDatetime.date,
        timeFrame:
          shipment.redeliveryContext.adjustedRedeliveryDatetime.timeFrame,
      });
    } else if (shipment.desiredDate || shipment.desiredTime) {
      /* 配達希望日（出荷時）が登録されている場合 */
      result.push({
        date: shipment.desiredDate,
        timeFrame: shipment.desiredTime,
      });
    }

    return result;
  },
};

export default Object.freeze(desiredDateTime);

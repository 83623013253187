<script>
  import Button from "@smui/button";
  import Dialog, { Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { activeContent } from "~/libs/stores";
  import { toast } from "~/libs/toast";

  /** ダイアログの開閉フラグ @type {boolean} */
  export let open;

  /** ヘッダ上の会社名を再読み込みする関数 @type {function} */
  export let reloadCurrentCompanyName;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 切り替え可能なECサイト @type {Array<import("~/libs/backendApi").Company>} */
  const options =
    userContext.loginUser.switchableCompanies?.sort((a, b) => a.id - b.id) ??
    [];

  /** 選択中の切替え先ECサイト @type {number} */
  let selected = userContext.loginUser.currentCompanyId;

  /** 「切替え」ボタンの有効化フラグ */
  $: changeButtonDisabled = selected === userContext.loginUser.currentCompanyId;

  const changeRole = loadingProgress.wrapAsync(async () => {
    try {
      // ログインAPI呼び出し
      const loginResponse = await backendApi.loginToSwitchCompany(
        {
          username: userContext.loginUser.username,
          password: "N/A",
        },
        selected,
      );

      // ログイン情報をUserContextに保存
      userContext.loginUser.accessToken = loginResponse.accessToken;
      userContext.loginUser.expiresIn = loginResponse.expiresIn;
      userContext.loginUser.loginTime = Date.now();
      userContext.loginUser.currentCompanyId = loginResponse.currentCompanyId;

      // EC設定取得API呼び出し
      try {
        const ecSettings = await backendApi.getEcSettings();
        // EC設定情報をUserContextに追加設定
        userContext.ecSettings = ecSettings;
      } catch (error) {
        userContext.erase(); // 暫定設定を取り消し
        throw error;
      }

      // UserContextの設定を保存してホーム画面に移動
      userContext.store();
      activeContent.set("Home");
      reloadCurrentCompanyName();
      toast.info($_("message.roleChangeComplete"));
      open = false;
    } catch (error) {
      console.error(error);
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.updateForbidden.message"));
      } else {
        // サーバーエラー応答等が発生した場合
        toast.error($_("errors.updateDefaultMessage.message"));
      }
    }
  });
</script>

<div class="roleChangeDialog">
  <Dialog bind:open style="max-height: 90%; position: absolute; z-index: 1000;">
    <Title
      style="border-bottom: solid; border-width: 2px; border-color: rgb(220, 220, 220); display: flex; justify-content: space-between; padding-top: 20px;"
    >
      <p>操作対象のECサイトを切替</p>
      <div class="closeButtonArea">
        <button
          class="closeButton"
          on:click={() => {
            open = false;
          }}><span class="material-icons">close</span></button
        >
      </div>
    </Title>

    <Content>
      <div class="formArea">
        <div class="radioWrapper">
          {#each options as option}
            <FormField style="margin-right: 10px;">
              <Radio bind:group={selected} value={option.id} />
              <span slot="label">
                {option.name}
              </span>
            </FormField>
          {/each}
        </div>

        <div class="submitArea">
          <Button
            variant="raised"
            style="width: 170px;"
            on:click={changeRole}
            bind:disabled={changeButtonDisabled}>切替</Button
          >
        </div>
      </div>
    </Content>
  </Dialog>
</div>

<style lang="scss">
  .roleChangeDialog {
    :global(.mdc-dialog__surface) {
      max-width: 800px;
    }

    .formArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      padding: 25px 0 20px;
    }

    .radioWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .submitArea {
      text-align: center;
    }

    .closeButtonArea {
      margin: 0 0 0 auto;

      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
      button:focus {
        outline: 0;
      }

      .material-icons {
        font-size: 30px;
      }
    }
  }
</style>

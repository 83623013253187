<script>
  import ManagementResultTable from "~/pages/Management/ManagementResultTable.svelte";

  /** @type {Array<import("~/libs/commonTypes").CustomedUserInfo>} */
  export let results;
  export let count;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;
</script>

<div id="dataComment">
  <h1 class="resultNumber">検索結果 {count.toLocaleString()}件</h1>
  {#if count > 1000}
    <p class="notes">※以下一覧には1,000件までしか表示されません。</p>
  {/if}
  <div class="searchResultTable">
    <svelte:component
      this={ManagementResultTable}
      {results}
      {companyNameList}
    />
  </div>
</div>

<style>
  .resultNumber {
    margin: 10px 15px;
  }
  .notes {
    font-size: smaller;
    margin-left: 15px;
    margin-bottom: 8px;
    color: red;
  }
  .searchResultTable {
    overflow: auto;
  }
</style>

<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import {
    CONTEXT_KEY_USER,
    EC_SELECTABLE_DROPPLACE_LIST,
  } from "~/libs/constants";

  export let record;

  /** @type {boolean} */
  export let supportCashOnDelivery;

  /** @type {Function} */
  export let closeHandlerAction;

  /** @type {string} */
  export let formatType;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let open = true;
  let inputRecord = {
    index: record.index,
    customerOrderId: record.customerOrderId ?? "",
    trackingNumber: record.trackingNumber ?? "",
    receiverPostcode: record.receiverPostcode ?? "",
    receiverAddress1: record.receiverAddress1 ?? "",
    receiverAddress2: record.receiverAddress2 ?? "",
    receiverName: record.receiverName ?? "",
    receiverTel: record.receiverTel ?? "",
    receiverEmailAddress: record.receiverEmailAddress ?? "",
    shipperPostcode: record.shipperPostcode ?? "",
    shipperAddress1: record.shipperAddress1 ?? "",
    shipperAddress2: record.shipperAddress2 ?? "",
    shipperName: record.shipperName ?? "",
    shipperTel: record.shipperTel ?? "",
    shipperRemarks1: record.shipperRemarks1 ?? "",
    shipperRemarks2: record.shipperRemarks2 ?? "",
    desiredDate: record.desiredDate
      ? record.desiredDate.replace(/\//g, "-")
      : "",
    desiredTime: record.desiredTime ?? "",
    packageDropPlace: record.packageDropPlace
      ? record.packageDropPlace
      : undefined,
    numberOfPackages: record.numberOfPackages ?? "",
    cashOnDeliveryAmount: record.cashOnDeliveryAmount ?? "",
    deliveryPrecautions: record.deliveryPrecautions ?? "",
  };
  let selectableDropPlace = [];
  (() => {
    const registrableDeliveryMethod =
      userContext.ecSettings.registrableDeliveryMethod;
    EC_SELECTABLE_DROPPLACE_LIST.forEach((item) => {
      if (registrableDeliveryMethod.includes(item)) {
        selectableDropPlace.push(String(item));
      }
    });
    selectableDropPlace = selectableDropPlace;
  })();

  function closeHandler(event) {
    closeHandlerAction(event.detail.action, inputRecord);
  }
</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="edit-dialog-title"
  aria-describedby="edit-dialog-content"
  on:SMUIDialog:closed={closeHandler}
  style="margin-top: 80px; max-height: 90%; z-index: 100;"
>
  <Title id="edit-dialog-title">出荷情報の編集</Title>
  <Content id="edit-dialog-content" style="width: 520px;">
    <div class="text-area">
      <Textfield
        style="margin: 15px 1.0px 0 1.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.customerOrderId}
        label="注文番号"
      />
      {#if record.violations?.get("customerOrderId")}
        {#each record.violations?.get("customerOrderId") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 15px 1.0px 0 1.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.trackingNumber}
        label="送り状番号"
      />
      {#if record.violations?.get("trackingNumber")}
        {#each record.violations?.get("trackingNumber") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverPostcode}
        label="届け先郵便番号"
      />
      {#if record.violations?.get("receiverPostcode")}
        {#each record.violations?.get("receiverPostcode") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverAddress1}
        label="届け先住所１"
      />
      {#if record.violations?.get("receiverAddress1")}
        {#each record.violations?.get("receiverAddress1") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverAddress2}
        label="届け先住所２"
      />
      {#if record.violations?.get("receiverAddress2")}
        {#each record.violations?.get("receiverAddress2") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverName}
        label="届け先名"
      />
      {#if record.violations?.get("receiverName")}
        {#each record.violations?.get("receiverName") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverTel}
        label="届け先電話番号"
      />
      {#if record.violations?.get("receiverTel")}
        {#each record.violations?.get("receiverTel") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.receiverEmailAddress}
        label="届け先Eメールアドレス"
      />
      {#if record.violations?.get("receiverEmailAddress")}
        {#each record.violations?.get("receiverEmailAddress") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperPostcode}
        label="依頼主郵便番号"
      />
      {#if record.violations?.get("shipperPostcode")}
        {#each record.violations?.get("shipperPostcode") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperAddress1}
        label="依頼主住所１"
      />
      {#if record.violations?.get("shipperAddress1")}
        {#each record.violations?.get("shipperAddress1") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperAddress2}
        label="依頼主住所２"
      />
      {#if record.violations?.get("shipperAddress2")}
        {#each record.violations?.get("shipperAddress2") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperName}
        label="依頼主名"
      />
      {#if record.violations?.get("shipperName")}
        {#each record.violations?.get("shipperName") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperTel}
        label="依頼主電話番号"
      />
      {#if record.violations?.get("shipperTel")}
        {#each record.violations?.get("shipperTel") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperRemarks1}
        label="依頼主備考１"
      />
      {#if record.violations?.get("shipperRemarks1")}
        {#each record.violations?.get("shipperRemarks1") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.shipperRemarks2}
        label="依頼主備考２"
      />
      {#if record.violations?.get("shipperRemarks2")}
        {#each record.violations?.get("shipperRemarks2") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="textFieldArea">
      <label for="desiredDate">希望配達日</label>
      <input
        type="date"
        id="desiredDate"
        bind:value={inputRecord.desiredDate}
      />
    </div>
    {#if record.violations?.get("desiredDate")}
      {#each record.violations?.get("desiredDate") as violation}
        <p class="errorMsg">{violation.message} <br /></p>
      {/each}
    {/if}
    <div class="text-area">
      <Textfield
        style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.desiredTime}
        label="希望配達時間"
      />
      <p class="notice">※数字4桁で入力（例．9～12時の場合「0912」）</p>
      {#if record.violations?.get("desiredTime")}
        {#each record.violations?.get("desiredTime") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    <div class="textFieldArea">
      <label for="packageDropPlace">希望配達方法</label>
      <select id="packageDropPlace" bind:value={inputRecord.packageDropPlace}>
        <option value="">未選択</option>
        {#each selectableDropPlace as dropplace}
          <option value={dropplace}
            >{$_(`classes.packageDropPlace.${dropplace}`)}</option
          >
        {/each}
      </select>
    </div>
    {#if record.violations?.get("packageDropPlace")}
      {#each record.violations?.get("packageDropPlace") as violation}
        <p class="errorMsg">{violation.message} <br /></p>
      {/each}
    {/if}
    {#if supportCashOnDelivery}
      <div class="text-area">
        <Textfield
          style="margin: 10px 1.0px 2.0px; width: 99%; height: 45px;"
          variant="outlined"
          type="text"
          bind:value={inputRecord.cashOnDeliveryAmount}
          label="代引金額"
        />
        {#if record.violations?.get("cashOnDeliveryAmount")}
          {#each record.violations?.get("cashOnDeliveryAmount") as violation}
            <HelperText persistent slot="helper"
              >{violation.message} <br /></HelperText
            >
          {/each}
        {/if}
      </div>
    {/if}
    <div class="text-area">
      <Textfield
        style="margin: 15px 1.0px 0 1.0px; width: 99%; height: 45px;"
        variant="outlined"
        type="text"
        bind:value={inputRecord.numberOfPackages}
        label="個数"
      />
      {#if record.violations?.get("numberOfPackages")}
        {#each record.violations?.get("numberOfPackages") as violation}
          <HelperText persistent slot="helper"
            >{violation.message} <br /></HelperText
          >
        {/each}
      {/if}
    </div>
    {#if formatType === "2"}
      <div class="text-area">
        <Textfield
          style="margin: 15px 1.0px 0 1.0px; width: 99%; height: 45px;"
          variant="outlined"
          type="text"
          bind:value={inputRecord.deliveryPrecautions}
          label="配達時注意事項"
        />
        {#if record.violations?.get("deliveryPrecautions")}
          {#each record.violations?.get("deliveryPrecautions") as violation}
            <HelperText persistent slot="helper"
              >{violation.message} <br /></HelperText
            >
          {/each}
        {/if}
      </div>
    {/if}
  </Content>
  <Actions>
    <Button action={"cancel"}>
      <Label>キャンセル</Label>
    </Button>
    <Button action={"save"}>
      <Label>保存</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  .notice {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    color: darkslategrey;
  }
  .textFieldArea {
    display: flex;
    align-items: center;
    margin: 18px 0 8px;
    height: 44px;
    width: 416px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 0 16px;
    position: relative;
    border: 2px solid #ccc;
  }
  .textFieldArea:hover {
    border: 2px solid #1976d2;
  }
  .textFieldArea:has(input:focus) label,
  .textFieldArea:has(select:focus) label {
    color: #1976d2;
  }
  label {
    position: absolute;
    padding: 0 4px;
    background: #fff;
    color: #888;
    transform: translate(-17px, -28px) scale(0.72);
  }
  #desiredDate,
  #packageDropPlace {
    border: none;
    outline: none;
    height: 1.4em;
    width: 100%;
  }
  .text-area {
    margin-bottom: 6px;
    :global(.mdc-text-field-helper-text--persistent) {
      color: red;
      margin-left: 4px;
      line-height: 1.2;
    }
  }
  .errorMsg {
    color: red;
    font-size: 12px;
    margin-left: 4px;
    margin-bottom: 10px;
    line-height: 1.2;
  }
</style>

<script>
  import Button from "@smui/button";
  import { getContext } from "svelte";
  import { push } from "svelte-spa-router";

  import ecBanner from "~/assets/images/ec_banner.png";
  import knowledgeBanner from "~/assets/images/knowledge_banner.png";
  import manageBanner from "~/assets/images/manage_banner.png";
  import searchBanner from "~/assets/images/search_banner.png";
  import uploadBanner from "~/assets/images/upload_banner.png";
  import Edit from "~/components/icons/edit.svelte";
  import Mail from "~/components/icons/mail.svelte";
  import Search from "~/components/icons/search.svelte";
  import UploadNewData from "~/components/icons/upload-new-data.svelte";
  import User from "~/components/icons/user.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { activeContent, targetTrackingNumber } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  let trackingNumber;
  let displayErrorMessage = "none";

  const changeContentUpload = async () => {
    activeContent.set("Upload");
    return;
  };

  const changeContentSearch = async () => {
    activeContent.set("Search");
    return;
  };

  const changeContentManagement = async () => {
    activeContent.set("Management");
    return;
  };

  const changeContentEcmall = async () => {
    activeContent.set("Ecmall");
    return;
  };

  const changeContentKnowledge = async () => {
    activeContent.set("Knowledge");
    return;
  };

  const changeContentSearchWithTrackingNumber = async () => {
    if (trackingNumber.length >= 12) {
      displayErrorMessage = "none";
      targetTrackingNumber.set(trackingNumber);
      activeContent.set("Search");
    } else {
      displayErrorMessage = "block";
    }
    return;
  };
</script>

<div class="all">
  <div class="searchArea">
    <div class="funcArea">
      <div class="funcTitle">送り状番号検索</div>
      <span class="funcComment">送り状番号を用いて配送情報を検索します。</span>
    </div>
    <div class="inputArea">
      <div>
        <input
          class="inputText"
          type="text"
          bind:value={trackingNumber}
          placeholder="送り状番号を入力"
        />

        <p class="errorMsg" style="display: {displayErrorMessage}">
          ※番号を正しく入力してください。
        </p>
      </div>
      <Button
        style="margin-top: 0; margin-left: 10px; height: 35px; width: 60px;"
        touch
        variant="unelevated"
        on:click={changeContentSearchWithTrackingNumber}
        >検索
      </Button>
    </div>
  </div>
</div>
{#if userContext.hasEcAdminRole()}
  <div class="flexContainer ecContainer">
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 8px 0 0 10px; width: 180px"
            src={uploadBanner}
            alt="uploadBanner"
          />
        </h1>
      </div>
      <div class="buttonArea largeButtonArea">
        <label class="funcButton">
          <div class="icons" style="width: 54px; margin: 8px auto 6px;">
            <UploadNewData />
          </div>
          <input type="button" id="button" on:click={changeContentUpload} /><br
          />
          <div class="funcTitle">新規データから</div>
          <span class="funcComment"
            >CSVファイルをアップロードして<br
            />送り状発行データを作成します</span
          >
        </label>
      </div>
    </div>
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={searchBanner}
            alt="searchBanner"
          />
        </h1>
      </div>
      <div class="buttonArea largeButtonArea">
        <label class="funcButton">
          <div class="icons" style="width: 80px;">
            <Search />
          </div>
          <input type="button" id="button" on:click={changeContentSearch} /><br
          />
          <div class="funcTitle">その他条件での検索</div>
          <span class="funcComment"
            >送り状番号以外の情報を用いて<br />配送情報を検索します</span
          >
        </label>
      </div>
    </div>
  </div>
  <div class="flexContainer ecContainer">
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={manageBanner}
            alt="manageBanner"
          />
        </h1>
      </div>
      <div class="all">
        <div class="buttonArea largeButtonArea">
          <label class="funcButton">
            <div class="icons" style="width: 80px;">
              <User />
            </div>
            <input
              type="button"
              id="button"
              on:click={changeContentManagement}
            /><br />
            <div class="funcTitle">ユーザーの管理</div>
            <span class="funcComment"
              >アカウントの追加・削除・<br />
              パスワード変更などを行います</span
            >
          </label>
        </div>
      </div>
    </div>
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 180px"
            src={ecBanner}
            alt="ecBanner"
          />
        </h1>
      </div>
      <div class="all">
        <div class="buttonArea largeButtonArea">
          <label class="funcButton">
            <div class="icons" style="width: 80px;">
              <Mail />
            </div>
            <input
              type="button"
              id="button"
              on:click={changeContentEcmall}
            /><br />
            <div class="funcTitle">楽天市場利用設定</div>
            <span class="funcComment"
              >楽天あんしんメルアドサービスを利用<br
              />してメールを送る設定を行います</span
            >
          </label>
        </div>
      </div>
    </div>
  </div>
{:else if userContext.hasShippingPartnerAdminRole() || userContext.hasSccOperatorRole()}
  <div class="flexContainer ecContainer">
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={searchBanner}
            alt="searchBanner"
          />
        </h1>
      </div>
      <div class="buttonArea largeButtonArea">
        <label class="funcButton">
          <div class="icons" style="width: 80px;">
            <Search />
          </div>
          <input type="button" id="button" on:click={changeContentSearch} /><br
          />
          <div class="funcTitle">その他条件での検索</div>
          <span class="funcComment"
            >送り状番号以外の情報を用いて<br />配送情報を検索します</span
          >
        </label>
      </div>
    </div>
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 154px"
            src={knowledgeBanner}
            alt="knowledgeBanner"
          />
        </h1>
      </div>
      <div class="all">
        <div class="buttonArea largeButtonArea">
          <label class="funcButton">
            <div class="icons" style="width: 72px; margin: 4px auto;">
              <Edit />
            </div>
            <input
              type="button"
              id="button"
              on:click={changeContentKnowledge}
            /><br />
            <div class="funcTitle">共有配達メモ管理</div>
            <span class="funcComment">
              ドライバーが登録した共有配達メモの<br
              />閲覧・編集などを行います</span
            >
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="flexContainer ecContainer">
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={manageBanner}
            alt="manageBanner"
          />
        </h1>
      </div>
      <div class="all">
        <div class="buttonArea largeButtonArea">
          <label class="funcButton">
            <div class="icons" style="width: 80px;">
              <User />
            </div>
            <input
              type="button"
              id="button"
              on:click={changeContentManagement}
            /><br />
            <div class="funcTitle">ユーザーの管理</div>
            <span class="funcComment"
              >アカウントの追加・削除・<br />
              パスワード変更などを行います</span
            >
          </label>
        </div>
      </div>
    </div>
  </div>
{:else}
  <div class="flexContainer ecContainer">
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={searchBanner}
            alt="searchBanner"
          />
        </h1>
      </div>
      <div class="buttonArea largeButtonArea">
        <label class="funcButton">
          <div class="icons" style="width: 80px;">
            <Search />
          </div>
          <input type="button" id="button" on:click={changeContentSearch} /><br
          />
          <div class="funcTitle">その他条件での検索</div>
          <span class="funcComment"
            >送り状番号以外の情報を用いて<br />配送情報を検索します</span
          >
        </label>
      </div>
    </div>
    <div class="contentArea">
      <div class="titleArea">
        <div class="titleLine" />
        <h1 class="title">
          <img
            style="margin: 10px 0 0 10px; width: 130px"
            src={manageBanner}
            alt="manageBanner"
          />
        </h1>
      </div>
      <div class="all">
        <div class="buttonArea largeButtonArea">
          <label class="funcButton">
            <div class="icons" style="width: 80px;">
              <User />
            </div>
            <input
              type="button"
              id="button"
              on:click={changeContentManagement}
            /><br />
            <div class="funcTitle">ユーザーの管理</div>
            <span class="funcComment"
              >アカウントの追加・削除・<br />
              パスワード変更などを行います</span
            >
          </label>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .searchArea {
    display: flex;
    margin: 16px 20px 0 50px;
    height: 70px;
    width: 850px;
    min-width: 745px;
    padding-top: 16px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: #000000;
    font-size: 16px;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
  }
  .inputArea {
    margin: 4px 0 0 20px;
    display: flex;
    align-items: top;
  }
  .inputText {
    height: 29px;
    width: 250px;
    text-align: center;
  }
  .errorMsg {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
    color: red;
  }
  .funcTitle {
    margin-top: 8px;
    font-weight: 900;
    margin-bottom: 8px;
  }
  .funcComment {
    font-size: 12.6px;
  }
  .flexContainer {
    width: 850px;
    display: flex;
    justify-content: start;
  }
  .ecContainer {
    gap: 16px;
    margin: 16px 0 16px 50px;
  }
  .contentArea {
    width: 414px;
    height: 242px;
    flex-direction: column;
    border: 2px;
    border-color: #064491cb;
    border-style: solid;
    border-radius: 8px 8px 8px 8px;
  }
  .titleArea {
    height: 40px;
    display: flex;
    background-color: #064491cb;
    padding-bottom: 8px;
    border-radius: 4px 4px 0px 0px;
  }
  .title {
    display: flex;
    font-size: large;
    color: white;
    margin: auto auto auto 10px;
  }
  .buttonArea {
    width: 20px;
    height: 150px;
    position: relative;
  }
  .largeButtonArea {
    margin-top: 10px;
    margin-left: 90px;
  }
  .funcButton {
    position: absolute;
    width: 230px;
    height: 160px;
    padding-top: 15px;
    text-align: center;
    background: rgb(255, 255, 255);
    color: #000000;
    cursor: pointer;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  .funcButton:hover,
  .funcButton:focus {
    filter: drop-shadow(1px 1px 1px rgb(170, 170, 170));
  }
  .icons {
    display: inline-block;
  }
  #button {
    position: absolute;
    opacity: 0;
  }
</style>
